{
  "archived_Desc_Key": "Vous trouverez plus d'informations en cliquant ici.",
  "archived_Title_Key": "Ce document a été supprimé.",
  "carousel_Text_Key": "Cette information n'était pas utile? Essayez l'un des documents ci-dessous",
  "click_prompt_Text_Key": "ici",
  "close_Text_Key": "Fermer",
  "customerService_Text_Key": "Informations pratiques",
  "error_Text_Key": "Quelque chose ne va pas",
  "feedback_Option1_Text_Key": "Le texte est difficile à comprendre",
  "feedback_Option2_Text_Key": "Le texte n'est pas pertinent par rapport à ma question",
  "feedback_Option3_Text_Key": "Le texte est pertinent, mais il n'est pas utile",
  "feedback_Option4_Text_Key": "Le texte est trop long ou trop court ",
  "feedback_Question_1_Text_Key": "Quelles sont les parties de nos documents que nous pourrions améliorer ?",
  "feedback_Rec_Text_Key": "Merci pour votre retour",
  "feedback_Text_Key": "Avez-vous trouvé cette information utile?",
  "here_Text_Key": "ici.",
  "knowledge_Text_Key": "Base de connaissance",
  "link_prod_error_Text_Key": "https://www.ikea.com/fr/fr/customer-service/track-manage-order/",
  "loading_Text_Key": "Chargement",
  "market_Name_Key": "France",
  "modal_button_Close_Text_Key": "Fermer",
  "negative_feedback_Text_Key": "Nous sommes désolés que vous n’ayez pas obtenu la réponse que vous cherchiez, souhaitez-vous nous aider à nous améliorer ?",
  "no_results_Text_Key": "Nous n’avons pas trouvé de résultat pour: {{query}}",
  "product_error_Text_Key": "Vous cherchez un produit ? Merci d'utiliser le moteur de recherche situé en haut de page. Si vous souhaitez suivre l'état d'avancement de votre commande, c'est par",
  "read_more_button_Text_Key": "En savoir plus",
  "results_Text_Key": "Nous avons trouvé {{number}} résultat(s) pour: {{query}}",
  "retrieving_Text_Key": "Récupération",
  "searchbar_Text_Key": "Posez votre question ici",
  "searching_Text_Key": "En cours de recherche…",
  "showLess_Text_Key": "Afficher moins",
  "showMore_Text_Key": "Afficher plus",
  "spellcheck_Text_Key": "Vérifiez l’orthographe ou utilisez d’autres mots clef",
  "submit_Text_Key": "Envoyer",
  "try_again_Text_Key": "Merci de réessayer ultérieurement"
}