{
  "archived_Desc_Key": "Further help can be found here",
  "archived_Title_Key": "This document has been archived.",
  "carousel_Text_Key": "Was the article not helpful? Try one of the related articles below.",
  "click_prompt_Text_Key": "Here",
  "close_Text_Key": "Close",
  "customerService_Text_Key": "Customer Service",
  "error_Text_Key": "Oops, something went wrong",
  "feedback_Option1_Text_Key": "The text is confusing to understand",
  "feedback_Option2_Text_Key": "The text is not relevant to my question",
  "feedback_Option3_Text_Key": "The text is relevant but not helpful",
  "feedback_Option4_Text_Key": "The text is too long or too short ",
  "feedback_Question_1_Text_Key": "What particular parts of our articles do you find unsatisfactory or unhelpful?",
  "feedback_Rec_Text_Key": "Thank you for your feedback!",
  "feedback_Text_Key": "Did you find this useful?",
  "here_Text_Key": "here",
  "knowledge_Text_Key": "Knowledge",
  "link_prod_error_Text_Key": "hhttps://www.ikea.com/jp/en/",
  "loading_Text_Key": "Loading",
  "market_Name_Key": "Japan",
  "modal_button_Close_Text_Key": "Close",
  "negative_feedback_Text_Key": "We are sorry you didn’t get the answer you were looking for, would you like to help us improve?",
  "no_results_Text_Key": "We did not find any results for: {{query}}",
  "product_error_Text_Key": "If you want to search for product information, please use the search bar at the top of the IKEA website.",
  "read_more_button_Text_Key": "Read more",
  "results_Text_Key": "We found {{number}} results for: {{query}}",
  "retrieving_Text_Key": "Retrieving",
  "searchbar_Text_Key": "Search inquiry by keyword",
  "searching_Text_Key": "Searching...",
  "showLess_Text_Key": "Show less",
  "showMore_Text_Key": "Show more",
  "spellcheck_Text_Key": "Try checking the spelling or using different keywords",
  "submit_Text_Key": "Submit",
  "try_again_Text_Key": "Please try again later"
}