"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Loading = _ref => {
  let {
    className,
    prefix,
    children: child,
    text,
    labelTransitions,
    labelPosition,
    ...otherProps
  } = _ref;
  const baseClass = `${prefix}loading`;
  const [displayText, setDisplayText] = (0, _react.useState)(text);
  const [fadeOut, setFadeOut] = (0, _react.useState)(false);
  const [fadeIn, setFadeIn] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    let fadeOutTimer = 0;
    let fadeInTimer = 0;
    if (labelTransitions && text !== displayText) {
      setFadeIn(false);
      setFadeOut(true);
      fadeOutTimer = setTimeout(() => {
        setFadeOut(false);
        fadeInTimer = setTimeout(() => {
          setFadeIn(true);
          setDisplayText(text);
        }, 100);
      }, 100);
    } else {
      setFadeIn(true);
      setDisplayText(text);
    }
    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(fadeInTimer);
    };
  }, [text]);
  const loadingClass = (0, _classnames.default)(baseClass, `${baseClass}--label-${labelPosition}`, className);
  const loadingTextClasses = (0, _classnames.default)(`${baseClass}__text`, fadeOut && `${baseClass}__text--fade-out`, fadeIn && `${baseClass}__text--fade-in`);
  const shouldRenderLabel = child && !child.props.size || child && child.props.size === 'large';
  return _react.default.createElement("span", _extends({}, otherProps, {
    className: loadingClass,
    "aria-live": "polite"
  }), child && (0, _react.cloneElement)(child, {
    prefix
  }), shouldRenderLabel && _react.default.createElement("span", {
    "aria-hidden": true,
    className: loadingTextClasses
  }, displayText), _react.default.createElement("span", {
    className: `${baseClass}__sr-only-text`
  }, text));
};
Loading.defaultProps = {
  prefix: '',
  text: 'Loading…',
  labelTransitions: false,
  labelPosition: 'center'
};
var _default = Loading;
exports.default = _default;