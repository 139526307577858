{
  "archived_Desc_Key": "Dodatnu pomoć možeš pronaći ovdje.",
  "archived_Title_Key": "Ovaj članak je arhiviran.",
  "carousel_Text_Key": "Ovaj članak nije odgovorio na sva tvoja pitanja? Odgovore potraži u jednom od povezanih članaka u nastavku.",
  "click_prompt_Text_Key": "ovdje",
  "close_Text_Key": "Zatvori",
  "customerService_Text_Key": "Služba za kupce",
  "error_Text_Key": "Došlo je do greške",
  "feedback_Option1_Text_Key": "Tekst je zbunjujući i teško ga je razumjeti",
  "feedback_Option2_Text_Key": "Tekst nije relevantan za moje pitanje",
  "feedback_Option3_Text_Key": "Tekst je relevantan, ali nije koristan",
  "feedback_Option4_Text_Key": "Tekst je predugačak ili prekratak ",
  "feedback_Question_1_Text_Key": "Koji dio našeg članaka nije zadovoljavajući ili nije koristan?",
  "feedback_Rec_Text_Key": "Hvala ti na povratnoj informaciji!",
  "feedback_Text_Key": "Je li ti ovo bilo korisno?",
  "here_Text_Key": "ovdje.",
  "knowledge_Text_Key": "Baza znanja",
  "link_prod_error_Text_Key": "https://www.ikea.com/hr/hr/customer-service/track-manage-order/",
  "loading_Text_Key": "Učitavanje",
  "market_Name_Key": "Hrvatska",
  "modal_button_Close_Text_Key": "Zatvori",
  "negative_feedback_Text_Key": "Žao nam je što nisi dobio/la odgovor koji si želio/željela. Želiš li nam pomoći da se poboljšamo?",
  "no_results_Text_Key": "Nismo pronašli nijedan rezultat za tvoj unos: {{query}}",
  "product_error_Text_Key": "Ako tražiš proizvod, molimo te da upotrijebiš traku za pretraživanje na vrhu stranice. Ako tražiš informacije o svojoj narudžbi, molimo te da klikneš",
  "read_more_button_Text_Key": "Pročitaj više",
  "results_Text_Key": "Pronašli smo {{number}} rezultat/a za: {{query}}",
  "retrieving_Text_Key": "Prikupljanje",
  "searchbar_Text_Key": "Pokušaj upisati na primjer: dostava ili reklamacija",
  "searching_Text_Key": "Pretraživanje...",
  "showLess_Text_Key": "Prikaži manje",
  "showMore_Text_Key": "Prikaži više",
  "spellcheck_Text_Key": "Provjeri je li unos ispravan ili koristi druge ključne riječi",
  "submit_Text_Key": "Pošalji",
  "try_again_Text_Key": "Pokušaj ponovo kasnije"
}