"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _classnames = _interopRequireDefault(require("classnames"));
var _propTypes = require("prop-types");
var _aspectRatioBox = _interopRequireDefault(require("@ingka/aspect-ratio-box"));
var _image = _interopRequireDefault(require("@ingka/image"));
var _ssrIcon = _interopRequireDefault(require("@ingka/ssr-icon"));
var _arrowRight = _interopRequireDefault(require("@ingka/ssr-icon/paths/arrow-right"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Card = _ref => {
  let {
    prefix = '',
    className,
    title,
    label,
    body,
    ctaLabel,
    children,
    href,
    backgroundColor,
    imageProps,
    textColor,
    titleHeadingTag = 'h2',
    emphasised,
    compact,
    imagePosition = 'trailing',
    buttonVariant = 'primary',
    large,
    ...other
  } = _ref;
  const backgroundColorStyle = backgroundColor ? {
    backgroundColor
  } : null;
  const textColorStyle = textColor ? {
    color: textColor
  } : null;
  const cardClasses = (0, _classnames.default)(`${prefix}card`, !imageProps && `${prefix}card--without-image`, emphasised && `${prefix}card--emphasised`, large && `${prefix}card--large`, compact && `${prefix}card--compact`, imagePosition === 'trailing' && `${prefix}card--trailing-image`, className);
  const cardInfoClasses = (0, _classnames.default)(`${prefix}card__info`);
  const HeadingTag = titleHeadingTag;
  const textContent = _react.default.createElement("div", {
    className: `${prefix}card__text-wrapper`
  }, label && _react.default.createElement("span", {
    style: textColorStyle,
    className: `${prefix}card__label`
  }, label), title && _react.default.createElement(HeadingTag, {
    style: textColorStyle,
    className: `${prefix}card__title`
  }, title), body && _react.default.createElement("p", {
    style: textColorStyle,
    className: `${prefix}card__body`
  }, body), children);
  return _react.default.createElement("a", _extends({
    className: cardClasses,
    href: href,
    style: backgroundColorStyle
  }, other), imageProps && _react.default.createElement("div", {
    className: `${prefix}card__image`
  }, _react.default.createElement(_aspectRatioBox.default, {
    ratio: "wide",
    prefix: prefix,
    className: `${prefix}card__image-aspect-ratio`
  }, _react.default.createElement(_image.default, _extends({
    prefix: prefix
  }, imageProps)))), _react.default.createElement("div", {
    className: cardInfoClasses
  }, textContent, _react.default.createElement("span", {
    style: textColorStyle,
    className: (0, _classnames.default)(`${prefix}card__cta`, ctaLabel && `${prefix}card__cta--with-button`)
  }, ctaLabel || emphasised ? _react.default.createElement("span", {
    className: `${prefix}card__btn ${prefix}btn ${prefix}btn--${buttonVariant} ${prefix}btn--small`
  }, _react.default.createElement("span", {
    className: `${prefix}btn__inner`
  }, emphasised && buttonVariant.indexOf('icon') !== -1 && _react.default.createElement(_ssrIcon.default, {
    className: `${prefix}card__icon`,
    prefix: prefix,
    paths: _arrowRight.default
  }), ctaLabel && _react.default.createElement("span", {
    className: `${prefix}btn__label`
  }, ctaLabel))) : _react.default.createElement(_ssrIcon.default, {
    className: `${prefix}card__icon`,
    prefix: prefix,
    paths: _arrowRight.default
  }))));
};
var _default = Card;
exports.default = _default;