import { IDocument } from "../components/ArticleList/ArticleList";
import { sendGA } from "./Analytics";
import { DomainName } from "./Config";
``
export interface IGetSearchResultsParams {
    correlationId: string;
    query: string;
    backend: string; 
    locale: string;
    variant: "standard"
    isProd: boolean; 
    setResults: (value: React.SetStateAction<any>[]) => void;
    setLoading: (value: React.SetStateAction<boolean>) => void;
    setError: (value: React.SetStateAction<number>) => void;
    setReady: (value: React.SetStateAction<boolean>) => void;
    setRedirect: (value: React.SetStateAction<boolean>) => void;
}

export interface IGetDocResultParams {
    correlationId: string;
    docGuid: string;
    backend: string; 
    locale: string;
    isProd: boolean; 
    pos: number;
    query: string;
    setModalOpen:(value: React.SetStateAction<boolean>) => void; 
    setVetaDoc:(value: React.SetStateAction<IDocument>) => void; 
}

const LISA_SEARCH_PROD = "https://web-api.ikea.com/customer-service/knowledge/search-gql" 
const LISA_SEARCH_INT = "https://web-api.cte.ikeadt.com/customer-service/knowledge/search-gql" 
const LISA_DOC_PROD = "https://web-api.ikea.com/customer-service/knowledge/document-gql" 
const LISA_DOC_INT = "https://web-api.cte.ikeadt.com/customer-service/knowledge/document-gql"
const CLIENT_ID_PROD = "dde333e7-6433-46a4-aef5-34532aec4a88"
const CLIENT_ID_INT = "d00c771f-5d86-449f-bff9-e9bf42729e68"


export const GetSearchResults = async (params: IGetSearchResultsParams) => {
    const start = new Date().getTime();
    params.setLoading(true)
    const lang = getValFromLocale(params.locale, "lang")
    const cb = getValFromLocale(params.locale, "cb")
    const data = JSON.stringify({
        "query": `query{search(query:"${params.query}",lang:"${lang}",categoryBranch:"${cb}", origin:"${DomainName}"){ documents{ description, title, content, vetaGuid }}}`,
        "variables": null
    });
    await fetch(params.isProd ? LISA_SEARCH_PROD : LISA_SEARCH_INT, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'x-client-id': params.isProd ? CLIENT_ID_PROD : CLIENT_ID_INT
        },
        body: data,
    }).then(response => {
        const elapsed = new Date().getTime() - start; 
        if (response.status !== 200) {
            return { error: true };
        }
        response.json().then(data => {
            let res = mapLisaToVetaBody(data)
            if (res !== undefined) {
                params.setReady(true);
                params.setLoading(false);
                params.setResults(res.entries);
                if (res.entries.length <= 0) {
                    params.setError(0);
                } 
                const serializedArray = JSON.stringify(res);
                sessionStorage.setItem('queryString', serializedArray);
            } 
            const redirectPage = redirect(params.query);
            if (redirectPage) {
                sendGA({
                    action: 'search',
                    query: params.query,
                    loadTimeMs: elapsed,
                    variant: params.variant,
                    backend: params.backend,
                    correlationId: params.correlationId,
                    redirect:redirectPage
                });
                params.setResults([]);
                params.setRedirect(true)
            }else {
                sendGA({
                    results: res === undefined ? 0 : res.entries.length,
                    action: 'search',
                    query: params.query,
                    loadTimeMs: elapsed,
                    variant: params.variant,
                    backend: params.backend,
                    correlationId: params.correlationId,
                })
              }
        })
    }).catch(err => {
        params.setLoading(false);
        params.setError(err.request.status > 0 ? err.request.status : 500);
        params.setReady(true);
    });
}

export const GetDocumentResults = async (params: IGetDocResultParams) => {
    const start = new Date().getTime();
        const lang = getValFromLocale(params.locale, "lang")
        const data = JSON.stringify({
            "query": `query{GetDoc(vetaGuid:"${params.docGuid}",lang:"${lang}", origin:"${DomainName}"){ description, title, content, docMetaData{changeDate, publishDate, categories{branch, branchText}}}}`,
            "variables": null
        });
        await fetch(params.isProd ? LISA_DOC_PROD : LISA_DOC_INT, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'x-client-id': params.isProd ? CLIENT_ID_PROD : CLIENT_ID_INT
            },
            body: data,
        }).then(response => {
            const elapsed = new Date().getTime() - start; 
            if (response.status !== 200) {
                return { error: true };
            }
            response.json().then(data => {
                if (data !== undefined) {
                    const document = data.data?.GetDoc;
                    params.setVetaDoc({title: document.title, description: document.description, content: document.content})                 
                }
                sendGA({
                    action: 'articleOpen',
                    query: params.query,
                    guid: params.docGuid,
                    pos: params.pos,
                    loadTimeMs: elapsed,
                    variant: 'standard',
                    backend: params.backend,
                    correlationId: params.correlationId,
                })
                params.setModalOpen(true);
            })
        }).catch(err => {
        });
}

const getValFromLocale = (locale: string, val: string) => {
    let startIdx = locale.indexOf(val) + val.length + 1
    let endIdx = locale.indexOf("&", startIdx)
    let res = locale.substring(startIdx, endIdx)
    
    return res
}

const mapLisaToVetaBody = (lisaRes: any): any => {

    let res: {count: number, entries: any[] } = {count: 0, entries: new Array()}

    if (lisaRes === undefined) {
        return undefined
    }

    if (lisaRes.data === undefined) {
        return undefined
    }

    if (lisaRes.data === null) {
        return res 
    }

    res.count = lisaRes?.data?.search?.documents?.length
    lisaRes.data.search.documents.forEach((doc:any) => {
        res.entries.push({content: doc.content, title: doc.title, description: doc.description, metadata: {docGUID: doc.vetaGuid}});
    })

    return res; 
}

const redirect = (query: string): any => {
    const productRegex = /(\d+\.\d+\.\d+)|\b\d{8}\b/g;
    const orderNumbRegex = /\b\d{9,10}\b/g;
    const sparePartRegex = /\b\d{6}\b/g;

    const productMatch = query.match(productRegex);
    const orderMatch =  query.match(orderNumbRegex);
    const sparePartMatch =  query.match(sparePartRegex);
    
    if(productMatch) {
        return "productNumber"
      }else if(orderMatch) {
        return "orderNumber"
      }else if(sparePartMatch) {
        return "sparePartNumber"
      }
    else {false}
}