/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import styled from "styled-components";
import { ArticleList } from "../index";
import { ErrorCard } from "../index";
import Loading, { LoadingBall } from "@ingka/loading";
import { Guid } from "guid-typescript";
import Expander from "@ingka/expander";
import { langStore } from "../../utils/translationObject";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/typography/dist/style.css";
import "@ingka/loading/dist/style.css";
import Search from "@ingka/search";
import { GetSearchResults } from "../../utils/HttpClient";

axios.defaults.timeout = 5000;

interface KnowledgeComponentProps {
  lang: string;
  variant: "standard";
}

const Container = styled.div`
  width: 100%;
  font-family: Noto IKEA, Noto Sans, Roboto, Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;

  justify-content: center;

  @media (max-width: 480px) {
    font-size: 15px;
  }

  @media (max-width: 375px) {
    font-size: 15px;
  }

  @media (max-width: 320px) {
    font-size: 15px;
  }
`;

const SearchBarContainer = styled.div`
  width: 100%;
  min-width: inherit;
  margin-bottom: 1rem;

  input {
    font-family: Noto IKEA, Noto Sans, Roboto, Open Sans, sans-serif;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
    min-width: unset;
  }

  @media (max-width: 320px) {
    font-size: 15px;
    min-width: unset;
  }
  .search__clear {
    position: absolute !important;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 480px) {
    width: 357px;
  }
`;

const SearchResultContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    margin-left: 0px;
    max-width: 358px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 24px;
`;

const maxSearchResult = 15;
const minSearchResult = 5;
const iterateNumArticles = 5;
type QueryType = string | null;

const KnowledgeComponent = ({ lang, variant }: KnowledgeComponentProps) => {
  const langKey: keyof typeof langStore = lang as any;

  const IsProduction = (): boolean => {
    const url = window.location.href;
    return url.includes("www.ikea.com");
  };
  const queryString = JSON.parse(sessionStorage.getItem("queryString"));
  const storedResult = queryString ? queryString.entries : [];
  const [query, setQuery] = useState<QueryType>("");
  const [results, setResults] = useState(storedResult ? storedResult : []);
  const [resultsShown, setResultsShown] = useState(minSearchResult);
  const [ready, setReady] = useState<boolean>(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | number>(undefined);
  const [regExError, setRegexError] = useState<boolean>(false);
  const [numberRegex, setNumberRegex] = useState<boolean>(false);
  // state for holding scope.
  const [correlationId, setCorrelationId] = useState("");
  const { i18n, t } = useTranslation();
  const [language, market] = langKey.split("-");
  const invalidCharRegex = /[()"{}\=\\]/;
  const productRegex = /(\d+(\.\d+)?\.\d+\.\d+)|\b\d{8}\b/g;
  const orderNumbRegex = /\b\d{9,10}\b/g;
  const sparePartRegex = /\b\d{6}\b/g;

  useEffect(() => {
    setRedirect(false);
    setResultsShown(minSearchResult);
    let backend = "lisa";
    async function runSearch() {
      setRegexError(false);
      if (query) {
        await GetSearchResults({
          correlationId,
          query,
          backend,
          variant,
          locale: t("locale"),
          isProd: IsProduction(),
          setResults,
          setLoading,
          setError,
          setReady,
          setRedirect,
        });
      }
    }
    runSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    openRedirect(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  setTimeout(() => {
    sessionStorage.removeItem("queryString");
  }, 60000);

  const searchFunc = async (e: React.BaseSyntheticEvent, data: any) => {
    checkRedirect(data.value);
    if (invalidCharRegex.test(data.value)) {
      setRegexError(true);
    }
    setQuery(data.value);
  };

  const openRedirect = (query: string): any => {
    const productMatch = query.match(productRegex);
    const orderMatch = query.match(orderNumbRegex);
    const sparePartMatch = query.match(sparePartRegex);

    if (productMatch && redirect) {
      window.location.href = `https://www.ikea.com/${market.toLowerCase()}/${language.toLowerCase()}/search/?q=${
        productMatch[0]
      }`;
    } else if (orderMatch && redirect) {
      window.location.href = `https://www.ikea.com/${market.toLowerCase()}/${language.toLocaleLowerCase()}/purchases/lookup/${
        orderMatch[0]
      }/`;
    } else if (sparePartMatch && redirect) {
      window.location.href = `https://www.ikea.com/${market.toLowerCase()}/${language.toLocaleLowerCase()}/customer-service/returns-claims/spareparts/`;
    }
  };

  const checkRedirect = (query: string): any => {
    const productRegex = /(\d+\.\d+\.\d+)|\b\d{8}\b/g;
    const orderNumbRegex = /\b\d{9,10}\b/g;
    const sparePartRegex = /\b\d{6}\b/g;

    const productMatch = query.match(productRegex);
    const orderMatch = query.match(orderNumbRegex);
    const sparePartMatch = query.match(sparePartRegex);

    if (productMatch || orderMatch || sparePartMatch) {
      setNumberRegex(true);
    }
  };

  const showMoreArticles = async (data: any) => {
    const articleResult = setNumberOfArticles();
    setResultsShown(
      data.target.textContent === langStore[langKey].showMore_Text_Key
        ? articleResult
        : minSearchResult
    );
  };

  const setLabelOnBtn = (): string => {
    return resultsShown >= results.length
      ? langStore[langKey].showLess_Text_Key
      : langStore[langKey].showMore_Text_Key;
  };

  const setNumberOfArticles = (): number => {
    const articleResult = resultsShown + iterateNumArticles;
    return articleResult > results.length ? results.length : articleResult;
  };

  const canShowButton = (): boolean => {
    if (results.length > minSearchResult) {
      return true;
    }
  };

  const articleToDisplay = (): any => {
    return results.slice(0, resultsShown);
  };

  useEffect(() => {
    setCorrelationId(Guid.create().toString());
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <Container>
      <SearchBarContainer>
        <Search
          className="searchBar"
          id="search"
          aria-label="Search"
          placeholder={langStore[langKey].searchbar_Text_Key}
          onSearch={searchFunc}
          clearBtnText={"clear search field"}
          onClear={() => {
            setQuery("");
            setReady(false);
            setResults([]);
          }}
        />
      </SearchBarContainer>

      {regExError ? (
        <ErrorCard lang={lang} message={""} />
      ) : query !== "" && query && lang !== "" && !loading ? (
        <SearchResultContainer>
          {ready && error ? (
            <ErrorCard
              lang={lang}
              errorCode={error}
              message={langStore[langKey].try_again_Text_Key}
            />
          ) : ready && results.length > 0 ? (
            <>
              {langStore[langKey].results_Text_Key
                .replace(
                  "{{number}}",
                  results.length > maxSearchResult
                    ? maxSearchResult
                    : results.length
                )
                .replace("{{query}}", query)}
            </>
          ) : ready && !numberRegex ? (
            <ErrorCard
              lang={lang}
              message={`${langStore[langKey].no_results_Text_Key.replace(
                "{{query}}",
                query
              )}`}
            />
          ) : null}
        </SearchResultContainer>
      ) : loading ? (
        <LoadingContainer>
          <Loading labelTransitions text={langStore[langKey].loading_Text_Key}>
            <LoadingBall />
          </Loading>
        </LoadingContainer>
      ) : (
        ""
      )}
      <ResultsContainer>
        {results && lang !== "" && (
          <>
            <ArticleList
              data={articleToDisplay()}
              lang={lang}
              query={query}
              variant={variant}
              correlationId={correlationId}
            />
            {canShowButton() && (
              <Expander
                generic
                onToggle={(e) => {
                  showMoreArticles(e);
                }}
                labelClosed={setLabelOnBtn()}
                labelOpen={setLabelOnBtn()}
              >
                {" "}
              </Expander>
            )}
          </>
        )}
      </ResultsContainer>
    </Container>
  );
};

export default KnowledgeComponent;
