{
  "archived_Desc_Key": "További segítség itt található",
  "archived_Title_Key": "Ezt a dokumentumot archiváltuk.",
  "carousel_Text_Key": "Nem volt hasznos a cikk? Próbáld ki az alábbi kapcsolódó cikkek egyikét.",
  "click_prompt_Text_Key": "itt",
  "close_Text_Key": "Bezárás",
  "customerService_Text_Key": "Ügyfélszolgálat",
  "error_Text_Key": "Hupsz! Valami",
  "feedback_Option1_Text_Key": "A szöveg megértése zavaros",
  "feedback_Option2_Text_Key": "A szöveg nem releváns a kérdésem szempontjából",
  "feedback_Option3_Text_Key": "A szöveg releváns, de nem hasznos",
  "feedback_Option4_Text_Key": "A szöveg túl hosszú vagy túl rövid",
  "feedback_Question_1_Text_Key": "Cikkeink mely részeit találja elégtelennek vagy nem hasznosnak?",
  "feedback_Rec_Text_Key": "Köszönjük a visszajelzésedet!",
  "feedback_Text_Key": "Hasznosnak találtad ezt a cikket?",
  "here_Text_Key": "itt",
  "knowledge_Text_Key": "Tudás",
  "link_prod_error_Text_Key": "https://www.ikea.com/hu/hu/customer-service/",
  "loading_Text_Key": "Betöltés...",
  "market_Name_Key": "Magyarország",
  "modal_button_Close_Text_Key": "Bezárás",
  "negative_feedback_Text_Key": "Sajnáljuk, hogy nem kaptad meg a választ, amit kerestél – szeretnél segíteni, hogy jobbá váljunk?",
  "no_results_Text_Key": "Nincs találat erre a keresőszóra: {{query}}",
  "product_error_Text_Key": "Ez a kereső csak segítő információk és cikkek megjelenítésére szolgál. Termékinformációért kérjük használd a IKEA.com/hu főoldalán található keresőt.",
  "read_more_button_Text_Key": "Bővebben",
  "results_Text_Key": "{{number}} találat ezekre a keresőszavakra: {{query}}",
  "retrieving_Text_Key": "Visszakeresés",
  "searchbar_Text_Key": "Találd meg a választ a kérdéseidre",
  "searching_Text_Key": "Keresés...",
  "showLess_Text_Key": "Mutass kevesebbet",
  "showMore_Text_Key": "Mutass többet",
  "spellcheck_Text_Key": "Ellenőrizd az elgépeléseket, vagy próbálj meg más keresőszót használni.",
  "submit_Text_Key": "Benyújtja",
  "try_again_Text_Key": "Kérlek próbáld újra"
}