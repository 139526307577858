{
  "archived_Desc_Key": "We helpen je graag verder via deze pagina",
  "archived_Title_Key": "Dit document is gearchiveerd.",
  "carousel_Text_Key": "Kunnen we je verder helpen?",
  "click_prompt_Text_Key": "Klik hier",
  "close_Text_Key": "Sluiten",
  "customerService_Text_Key": "Klantenservice",
  "error_Text_Key": "Er ging iets mis",
  "feedback_Option1_Text_Key": "De tekst is verwarrend om te begrijpen",
  "feedback_Option2_Text_Key": "De tekst is niet relevant voor mijn vraag",
  "feedback_Option3_Text_Key": "De tekst is relevant maar niet nuttig",
  "feedback_Option4_Text_Key": "De tekst is te lang of te kort ",
  "feedback_Question_1_Text_Key": "Welke specifieke onderdelen van onze artikelen vind je onbevredigend of niet behulpzaam?",
  "feedback_Rec_Text_Key": "Bedankt voor je feedback!",
  "feedback_Text_Key": "Vond je dit nuttig?",
  "here_Text_Key": "via deze pagina",
  "knowledge_Text_Key": "Kennis",
  "link_prod_error_Text_Key": "https://www.ikea.com/be/nl/customer-service/track-manage-order/",
  "loading_Text_Key": "Laden",
  "market_Name_Key": "België",
  "modal_button_Close_Text_Key": "Sluiten",
  "negative_feedback_Text_Key": "Het spijt ons dat je niet het antwoord hebt gekregen dat je zocht, wil je ons helpen om dit te verbeteren?",
  "no_results_Text_Key": "We vonden geen resultaten voor {{query}}",
  "product_error_Text_Key": "Deze zoekopdracht is alleen voor ondersteunende artikelen. Voor artikelnummers, probeer te zoeken in de bovenste zoekbalk op IKEA.be. Zoek je naar informatie over je bestelling?",
  "read_more_button_Text_Key": "Lees meer",
  "results_Text_Key": "We vonden {{number}} resultaten voor: {{query}}",
  "retrieving_Text_Key": "Gegevens ophalen",
  "searchbar_Text_Key": "Bijvoorbeeld: Levering, Retourneren, click & collect",
  "searching_Text_Key": "Zoeken...",
  "showLess_Text_Key": "Lees minder",
  "showMore_Text_Key": "Lees meer",
  "spellcheck_Text_Key": "Probeer het opnieuw met een andere spelling of trefwoorden",
  "submit_Text_Key": "Stuur  in",
  "try_again_Text_Key": "Probeer later opnieuw"
}