{
  "archived_Desc_Key": "Dodatna pomoč je na voljo tukaj",
  "archived_Title_Key": "Ta dokument je bil arhiviran.",
  "carousel_Text_Key": "Nebol tento článok dostatočne užitočný? Vyskúšajte príbuzný obsah zobrazený nižšie.",
  "click_prompt_Text_Key": "Kliknite tu",
  "close_Text_Key": "Zavrieť",
  "customerService_Text_Key": "Zákaznícky servis",
  "error_Text_Key": "Niečo sa nepodarilo",
  "feedback_Option1_Text_Key": "Text je mätúci a nepochopiteľný",
  "feedback_Option2_Text_Key": "Text sa netýka mojej otázky",
  "feedback_Option3_Text_Key": "Text sa týka mojej otázky, ale nie je užitočný",
  "feedback_Option4_Text_Key": "Text je príliš dlhý alebo príliš krátky ",
  "feedback_Question_1_Text_Key": "Ktoré konkrétne časti našich článkov považujete za neuspokojivé alebo neužitočné?",
  "feedback_Rec_Text_Key": "Ďakujeme za vašu spätnú väzbu!",
  "feedback_Text_Key": "Bolo to pre vás užitočné?",
  "here_Text_Key": "tukaj",
  "knowledge_Text_Key": "Centrum poznatkov",
  "link_prod_error_Text_Key": "https://www.ikea.com/sk/sk/customer-service/track-manage-order/",
  "loading_Text_Key": "Načítavam",
  "market_Name_Key": "Slovenská republika",
  "modal_button_Close_Text_Key": "Zatvoriť",
  "negative_feedback_Text_Key": "Je nám ľúto, že ste nedostali odpoveď, ktorú ste hľadali, chcete nám pomôcť zlepšiť sa?",
  "no_results_Text_Key": "Nenašli sme žiadne zhody pre hľadaný výraz: {{query}}",
  "product_error_Text_Key": "Toto pole slúži na vyhľadanie nápovedy. Pokiaľ chcete hľadať výrobky na základe ich čísla, použite hlavné vyhľadávacie pole na IKEA.sk",
  "read_more_button_Text_Key": "Prečítať viac",
  "results_Text_Key": "Našli sme {{number}} výsledok/výsledky pre: {{query}}",
  "retrieving_Text_Key": "Načítavam",
  "searchbar_Text_Key": "Sem napíšte, čo hľadáte.",
  "searching_Text_Key": "Vyhľadávam...",
  "showLess_Text_Key": "Zobraziť menej",
  "showMore_Text_Key": "Zobraziť viac",
  "spellcheck_Text_Key": "Skúste skontrolovať pravopis alebo použite iné kľúčové slová",
  "submit_Text_Key": "Odoslať",
  "try_again_Text_Key": "Skúste to znovu neskôr prosím"
}