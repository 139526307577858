{
  "archived_Desc_Key": "Додаткову допомогу можна знайти тут",
  "archived_Title_Key": "Цей документ було архівовано.",
  "carousel_Text_Key": "Чи не була стаття корисною? Спробуйте одну зі споріднених статей нижче.",
  "click_prompt_Text_Key": "тут",
  "close_Text_Key": "Закрити",
  "customerService_Text_Key": "Служба підтримки клієнтів",
  "error_Text_Key": "Сталась помилка",
  "feedback_Option1_Text_Key": "Текст важко зрозуміти",
  "feedback_Option2_Text_Key": "Текст не відповідає на моє питання",
  "feedback_Option3_Text_Key": "Текст доречний, але не корисний",
  "feedback_Option4_Text_Key": "Текст занадто довгий або занадто короткий ",
  "feedback_Question_1_Text_Key": "Яку саме частину нашої статті ви вважаєте незадовільною чи некорисною?",
  "feedback_Rec_Text_Key": "Дякуємо за ваш відгук!",
  "feedback_Text_Key": "Чи була корисною ця інформація?",
  "here_Text_Key": "тут",
  "knowledge_Text_Key": "Знання",
  "link_prod_error_Text_Key": "https://www.ikea.com/ua/uk/customer-service/",
  "loading_Text_Key": "Завантаження",
  "market_Name_Key": "Україна",
  "modal_button_Close_Text_Key": "Закрити",
  "negative_feedback_Text_Key": "Нам шкода, що ви не отримали потрібної відповіді, чи не хотіли б ви допомогти нам стати краще?",
  "no_results_Text_Key": "Не знайдено збігів за запитом: {{query}}",
  "product_error_Text_Key": "На жаль, результатів не знайдено. Ця пошукова система підтримує тільки одне або більше ключових слів. Якщо вам потрібна інформація про наші товари, використовуйте основну пошукову систему, розташовану у верхній частині мережі. Якщо ви хочете перевірити стан замовлення, натисніть кнопку.",
  "read_more_button_Text_Key": "Докладніше",
  "results_Text_Key": "Ми знайшли {{number}} результат(ів) для: {{query}}",
  "retrieving_Text_Key": "Обробка",
  "searchbar_Text_Key": "Спробуйте, наприклад: доставка, або оплата",
  "searching_Text_Key": "Пошук...",
  "showLess_Text_Key": "Показати менше",
  "showMore_Text_Key": "Показати більше",
  "spellcheck_Text_Key": "Перевір, чи правильно написане слово, або спробуй ввести інше",
  "submit_Text_Key": "Представити",
  "try_again_Text_Key": "Будь ласка, спробуй пізніше"
}