{
  "archived_Desc_Key": "Pomoč lahko najdeš tukaj.",
  "archived_Title_Key": "Ta dokument je arhiviran.",
  "carousel_Text_Key": "Ali članek ni bil koristen? Poskusi z enim od povezanih člankov spodaj.",
  "click_prompt_Text_Key": "klikni tukaj",
  "close_Text_Key": "Zapri",
  "customerService_Text_Key": "Služba za pomoč kupcem",
  "error_Text_Key": "Prišlo je do napake",
  "feedback_Option1_Text_Key": "Besedilo ni jasno razumljivo",
  "feedback_Option2_Text_Key": "Besedilo ni povezano z mojim vprašanjem",
  "feedback_Option3_Text_Key": "Besedilo je relevantno, vendar ni koristno",
  "feedback_Option4_Text_Key": "Besedilo je predolgo ali prekratko ",
  "feedback_Question_1_Text_Key": "Kaj na našem članku se ti zdi pomanjkljivo ali nekoristno?",
  "feedback_Rec_Text_Key": "Hvala za povratne informacije!",
  "feedback_Text_Key": "Je bilo to uporabno?",
  "here_Text_Key": "tukaj.",
  "knowledge_Text_Key": "Zbirka znanja",
  "link_prod_error_Text_Key": "https://www.ikea.com/si/sl/customer-service/track-manage-order/",
  "loading_Text_Key": "Nalaganje",
  "market_Name_Key": "Slovenija",
  "modal_button_Close_Text_Key": "Zapri",
  "negative_feedback_Text_Key": "Žal nam je, da nisi dobil/-a odgovora, ki si ga iskal/-a. Ali nam želiš pomagati pri izboljšivah?",
  "no_results_Text_Key": "Ni bilo mogoče najti ujemanj za iskalne izraze: {{query}}",
  "product_error_Text_Key": "Če iščeš izdelek, uporabi brskalnik na vrhu spletne strani. Če iščeš informacije o svojem naročilu,",
  "read_more_button_Text_Key": "Preberi več",
  "results_Text_Key": "Število zadetkov za \"{{query}}\": {{number}}",
  "retrieving_Text_Key": "Pridobivanje",
  "searchbar_Text_Key": "Preizkusi na primer: dostava ali prevzem",
  "searching_Text_Key": "Iskanje...",
  "showLess_Text_Key": "Prikaži manj",
  "showMore_Text_Key": "Prikaži več",
  "spellcheck_Text_Key": "Preveri zapis besede ali uporabi druge ključne besede",
  "submit_Text_Key": "Pošlji",
  "try_again_Text_Key": "Pokušaj ponovo kasnije"
}