{
  "archived_Desc_Key": "För support, gå till ikea.se/kundservice",
  "archived_Title_Key": "Det här dokumentet är inte längre tillgängligt.",
  "carousel_Text_Key": "Hittade du inte det du sökte? Ta en titt på en av artiklarna i listan.",
  "click_prompt_Text_Key": "Klicka här",
  "close_Text_Key": "Stäng",
  "customerService_Text_Key": "Kundservice",
  "error_Text_Key": "Något gick fel",
  "feedback_Option1_Text_Key": "Texten är förvirrande och svår att förstå",
  "feedback_Option2_Text_Key": "Texten är inte relevant för min fråga",
  "feedback_Option3_Text_Key": "Texten är relevant men inte till någon hjälp",
  "feedback_Option4_Text_Key": "Texten är för lång eller för kort ",
  "feedback_Question_1_Text_Key": "Vilken eller vilka delar av artikeln kan vi göra bättre?",
  "feedback_Rec_Text_Key": "Tack för din feedback!",
  "feedback_Text_Key": "Hjälpte den här artikeln dig?",
  "here_Text_Key": "ikea.se/kundservice",
  "knowledge_Text_Key": "Kunskapsportal",
  "link_prod_error_Text_Key": "https://order.ikea.com/se/sv/purchases/lookup/",
  "loading_Text_Key": "Letar efter svaret...",
  "market_Name_Key": "Sverige",
  "modal_button_Close_Text_Key": "Stäng",
  "negative_feedback_Text_Key": "Vi beklagar att du inte fick det svar du letade efter, vill du hjälpa oss att bli bättre?",
  "no_results_Text_Key": "Vi hittade inga resultat för: {{query}}",
  "product_error_Text_Key": "Använd istället sökfältet högst upp på vår hemsida om du letar efter information om en specifik produkt. Vill du spåra eller hantera din order,",
  "read_more_button_Text_Key": "Läs mer",
  "results_Text_Key": "Vi hittade {{number}} resultat för: {{query}}",
  "retrieving_Text_Key": "Laddar...",
  "searchbar_Text_Key": " ",
  "searching_Text_Key": "Letar efter svaret...",
  "showLess_Text_Key": "Visa mindre",
  "showMore_Text_Key": "Visa mer",
  "spellcheck_Text_Key": "Testa att använda ett annat ord eller fras!",
  "submit_Text_Key": "Skicka in",
  "try_again_Text_Key": "Var snäll och försök igen senare."
}