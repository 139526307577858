import { easeEasy } from '@ingka/variables';
export const animationStates = {
    EXPANDED: 'EXPANDED',
    ANIMATING_TO_EXPANDED: 'ANIMATING_TO_EXPANDED',
    PREPARE_ANIMATE_FROM_EXPANDED: 'PREPARE_ANIMATE_FROM_EXPANDED',
    COLLAPSED: 'COLLAPSED',
    ANIMATING_TO_COLLAPSED: 'ANIMATING_TO_COLLAPSED',
    PREPARE_ANIMATE_FROM_COLLAPSED: 'PREPARE_ANIMATE_FROM_COLLAPSED'
};
const collapsedStyles = {
    height: 0,
    marginBottom: '0'
};
export const getStyles = {
    [animationStates.EXPANDED]: (marginBottom) => {
        return { marginBottom };
    },
    [animationStates.ANIMATING_TO_EXPANDED]: (marginBottom, duration, height) => {
        return {
            height: `${height || 0}px`,
            marginBottom,
            transition: `all ${duration}ms ${easeEasy}`
        };
    },
    [animationStates.PREPARE_ANIMATE_FROM_EXPANDED]: (marginBottom, _, height) => {
        return {
            height: `${height || 0}px`,
            marginBottom
        };
    },
    [animationStates.COLLAPSED]: () => collapsedStyles,
    [animationStates.ANIMATING_TO_COLLAPSED]: (_, duration) => {
        return {
            height: 0,
            marginBottom: '0',
            transition: `all ${duration}ms ${easeEasy}`
        };
    },
    [animationStates.PREPARE_ANIMATE_FROM_COLLAPSED]: () => collapsedStyles
};
