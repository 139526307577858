"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const LoadingBall = _ref => {
  let {
    className,
    prefix,
    size,
    color,
    ...otherProps
  } = _ref;
  const baseClass = `${prefix}loading__ball`;
  const loadingBallClass = (0, _classnames.default)(baseClass, `${baseClass}--${size}`, `${baseClass}--${color}`);
  return _react.default.createElement("span", _extends({}, otherProps, {
    className: loadingBallClass,
    "aria-hidden": true
  }));
};
LoadingBall.defaultProps = {
  prefix: '',
  size: 'large',
  color: 'emphasised'
};
var _default = LoadingBall;
exports.default = _default;