{
  "archived_Desc_Key": "Clicca qui per trovare il supporto di cui hai bisogno.",
  "archived_Redirection_Key": "customer-service/contact-us/",
  "archived_Title_Key": "Il contenuto relativo alla tua richiesta non è più disponibile.",
  "carousel_Text_Key": "Il documento non è stato utile? Scopri di più sullo stesso argomento qui sotto:",
  "click_prompt_Text_Key": "Clicca qui Verifica la consegna del tuo ordine - IKEA IT",
  "close_Text_Key": "Chiudi",
  "customerService_Text_Key": "Servizio Clienti",
  "error_Text_Key": "Ops! Qualcosa è andato storto!",
  "feedback_Option1_Text_Key": "E' difficile da capire",
  "feedback_Option2_Text_Key": "Non risponde alla mia domanda",
  "feedback_Option3_Text_Key": "Risponde alla domanda ma non è esauriente",
  "feedback_Option4_Text_Key": "Il testo è troppo lungo o troppo corto ",
  "feedback_Question_1_Text_Key": "In particolare, perché non lo trovi utile?",
  "feedback_Rec_Text_Key": "Grazie per il tuo feedback!",
  "feedback_Text_Key": "Hai trovato questo documento utile?",
  "here_Text_Key": "qui",
  "knowledge_Text_Key": "Domande Frequenti",
  "link_prod_error_Text_Key": "https://www.ikea.com/it/it/customer-service/track-manage-order/",
  "loading_Text_Key": "Ricerca in corso...",
  "market_Name_Key": "Italia",
  "modal_button_Close_Text_Key": "Chiudere",
  "negative_feedback_Text_Key": "Ci dispiace che tu non abbia trovato la risposta che cercavi, vuoi aiutarci a migliorare?",
  "no_results_Text_Key": "Nessun risultato disponibile per: {{query}}",
  "product_error_Text_Key": "Qui puoi esplorare le FAQ pensate per te. Se stai cercando un prodotto specifico, digita il codice in alto nella barra di ricerca del sito. Se vuoi tracciare o gestire il tuo ordine.",
  "read_more_button_Text_Key": "Per saperne di più",
  "results_Text_Key": "Sono stati trovati {{number}} risultati per: {{query}}",
  "retrieving_Text_Key": "Recupero",
  "searchbar_Text_Key": "Scrivi qui la tua domanda",
  "searching_Text_Key": "Ricerca in corso...",
  "showLess_Text_Key": "Mostra di meno",
  "showMore_Text_Key": "Mostra di più",
  "spellcheck_Text_Key": "Prova a riformulare la tua ricerca con altre parole o a scrivere una frase meno complessa. Ricorda di digitare i codici dei prodotti senza inserire . o ,",
  "submit_Text_Key": "Invia",
  "try_again_Text_Key": "Riprova più tardi"
}