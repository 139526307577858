"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Hyperlink = (0, _react.forwardRef)((props, ref) => {
  const {
    prefix,
    children,
    text,
    url,
    subtle,
    disabled,
    button,
    colour,
    className,
    onClick,
    newWindow,
    ariaControls,
    ...otherProps
  } = props;
  function preventClick(e) {
    e.preventDefault();
  }
  const cssStyles = (0, _classnames.default)(className, `${prefix}link`, colour && `${prefix}link--${colour}`, subtle && `${prefix}link--subtle`, disabled && `${prefix}link--disabled`);
  let role = button ? 'button' : null;
  role = disabled ? 'link' : role;
  return _react.default.createElement("a", _extends({
    ref: ref,
    target: newWindow ? '_blank' : null
  }, otherProps, {
    href: !disabled ? url : null,
    className: cssStyles,
    role: role,
    "aria-disabled": disabled && `${disabled}`,
    "aria-controls": ariaControls ? ariaControls : null,
    onClick: button && disabled ? preventClick : onClick
  }), text && text.length > 0 ? text : '', text && children ? ' ' : null, children);
});
Hyperlink.defaultProps = {
  disabled: false,
  url: '#',
  button: false,
  newWindow: false,
  prefix: ''
};
Hyperlink.displayName = 'Hyperlink';
var _default = Hyperlink;
exports.default = _default;