{
  "archived_Desc_Key": "この他のサポートはこちらをご確認ください。",
  "archived_Title_Key": "お探しの質問が見つかりません。",
  "carousel_Text_Key": "お役に立ちませんでしたか？以下もご参考ください。",
  "click_prompt_Text_Key": "Here",
  "close_Text_Key": "閉じる",
  "customerService_Text_Key": "カスタマーサービス",
  "error_Text_Key": "問題が発生しました。",
  "feedback_Option1_Text_Key": "文章がわかりにくい",
  "feedback_Option2_Text_Key": "探している質問に関連していない",
  "feedback_Option3_Text_Key": "探している質問だが情報が足りない",
  "feedback_Option4_Text_Key": "文章が長すぎる、または短すぎる ",
  "feedback_Question_1_Text_Key": "よくなかった理由を教えてください。",
  "feedback_Rec_Text_Key": "ご意見ありがとうございます。",
  "feedback_Text_Key": "お役に立ちましたか？",
  "here_Text_Key": "こちら",
  "knowledge_Text_Key": "よくある質問",
  "link_prod_error_Text_Key": "https://www.ikea.com/jp/ja/",
  "loading_Text_Key": "Loading",
  "market_Name_Key": "日本",
  "modal_button_Close_Text_Key": "閉じる",
  "negative_feedback_Text_Key": "お探しの回答が得られず申し訳ございません。改善にご協力いただけますか？",
  "no_results_Text_Key": "{{query}} 該当するものがありません。",
  "product_error_Text_Key": "ここの検索バーは、よくある質問内での検索となります。商品番号は、このウェブページ上部にある検索バーでの検索をお試しください。",
  "read_more_button_Text_Key": "もっと見る",
  "results_Text_Key": "{{query}} の検索結果 {{number}}件",
  "retrieving_Text_Key": "Loading",
  "searchbar_Text_Key": "質問をキーワードで検索",
  "searching_Text_Key": "検索中",
  "showLess_Text_Key": "表示を減らす",
  "showMore_Text_Key": "もっと見る",
  "spellcheck_Text_Key": "違うキーワードをお試しください。",
  "submit_Text_Key": "送信する",
  "try_again_Text_Key": "しばらくたってから、またお試しください。"
}