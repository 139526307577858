"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireDefault(require("react"));
var _classnames = _interopRequireDefault(require("classnames"));
var _headerPropTypeValidation = _interopRequireDefault(require("../../utils/headerPropTypeValidation"));
var _AutoFocusOnMount = _interopRequireDefault(require("../../utils/AutoFocusOnMount"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Prompt = _ref => {
  let {
    className,
    titleId,
    header,
    prefix,
    children,
    open,
    closeBtnClick,
    title,
    footer,
    describedById,
    ...other
  } = _ref;
  const classes = (0, _classnames.default)(`${prefix}prompt`, open ? `${prefix}prompt--enter` : `${prefix}prompt--close`, className);
  const hookedHeader = header ? _react.default.cloneElement(header, {
    closeBtnClick,
    prefix,
    backBtnClick: null
  }) : null;
  const hookedFooter = footer && _react.default.cloneElement(footer, {
    prefix,
    closeBtnClick
  }) || null;
  const [wrapperFocus, contentRef] = (0, _AutoFocusOnMount.default)();
  return _react.default.createElement("div", _extends({
    "aria-describedby": describedById,
    "aria-labelledby": titleId
  }, other, {
    className: classes,
    "aria-modal": "true",
    role: "dialog",
    tabIndex: "-1"
  }), hookedHeader, _react.default.createElement("div", {
    className: `${prefix}prompt__content-wrapper`
  }, _react.default.createElement("div", {
    ref: contentRef,
    "data-autofocus": wrapperFocus,
    tabIndex: "0",
    role: "document",
    className: `${prefix}prompt__content`
  }, _react.default.createElement("h2", {
    id: titleId,
    className: `${prefix}prompt__heading`
  }, title), children), hookedFooter));
};
Prompt.defaultProps = {
  open: false,
  prefix: ''
};
var _default = Prompt;
exports.default = _default;