{
  "archived_Desc_Key": "Yderligere hjælp kan findes her.",
  "archived_Title_Key": "Dette dokument er blevet arkiveret.",
  "carousel_Text_Key": "Fandt du ikke svar? Prøv en af relaterede artikler nedenfor.",
  "click_prompt_Text_Key": "klik her",
  "close_Text_Key": "Luk",
  "customerService_Text_Key": "Kundeservice",
  "error_Text_Key": "Noget gik galt",
  "feedback_Option1_Text_Key": "Teksten er forvirrende at forstå",
  "feedback_Option2_Text_Key": "Teksten er ikke relevant for mit spørgsmål",
  "feedback_Option3_Text_Key": "Teksten er relevant, men jeg fik ikke svar",
  "feedback_Option4_Text_Key": "Teksten er for lang eller for kort ",
  "feedback_Question_1_Text_Key": "Hvilke dele af artiklen, synes du, er uklare eller irrelevante?",
  "feedback_Rec_Text_Key": "Tak for din feedback!",
  "feedback_Text_Key": "Fandt du svar?",
  "here_Text_Key": "her.",
  "knowledge_Text_Key": "Information",
  "link_prod_error_Text_Key": "https://www.ikea.com/dk/da/customer-service/track-manage-order/",
  "loading_Text_Key": "Loading",
  "market_Name_Key": "Danmark",
  "modal_button_Close_Text_Key": "Luk",
  "negative_feedback_Text_Key": "Vi er kede af, at du ikke fik det svar, du ledte efter. Vil du hjælpe os med at blive bedre?",
  "no_results_Text_Key": "Der er ingen søgninger som matcher søgning på: {{query}}",
  "product_error_Text_Key": "Dette søgefelt er kun til support. Ved artikelnumre, prøv at søge i søgefeltet øverst på IKEA.dk i stedet. Hvis du søger efter information om din ordre,",
  "read_more_button_Text_Key": "Læs mere",
  "results_Text_Key": "Der blev fundet {{number}} resultat(er) for: {{query}}",
  "retrieving_Text_Key": "Modtager",
  "searchbar_Text_Key": "Skriv dit spørgsmål her",
  "searching_Text_Key": "Søger....",
  "showLess_Text_Key": "Vis mindre",
  "showMore_Text_Key": "Vis mere",
  "spellcheck_Text_Key": "Tjek for stavefejl eller prøv andre søgeord.",
  "submit_Text_Key": "Indsende",
  "try_again_Text_Key": "Prøv venligst igen"
}