{
  "archived_Desc_Key": "Hier findest du weitere Hilfe.",
  "archived_Title_Key": "Dieses Dokument wurde archiviert.",
  "carousel_Text_Key": "Brauchst du eine andere Information? Bestimmt hilft dir das weiter:",
  "click_prompt_Text_Key": "Klick hier",
  "close_Text_Key": "Schließen",
  "customerService_Text_Key": "Kundenservice",
  "error_Text_Key": "Da ist etwas schiefgelaufen..",
  "feedback_Option1_Text_Key": "Der Text ist verwirrend zu verstehen",
  "feedback_Option2_Text_Key": "Der Text ist für meine Frage nicht relevant",
  "feedback_Option3_Text_Key": "Der Text ist relevant, aber nicht hilfreich",
  "feedback_Option4_Text_Key": "Der Text ist zu lang oder zu kurz ",
  "feedback_Question_1_Text_Key": "Welche Abschnitte unserer Antwort findest du mangelhaft oder nicht hilfreich?",
  "feedback_Rec_Text_Key": "Danke für dein Feedback!",
  "feedback_Text_Key": "War das hilfreich?",
  "here_Text_Key": "Hier",
  "knowledge_Text_Key": "IKEA Wissen",
  "link_prod_error_Text_Key": "https://www.ikea.com/at/de/customer-service/track-manage-order/",
  "loading_Text_Key": "Laden...",
  "market_Name_Key": "Österreich",
  "modal_button_Close_Text_Key": "Schließen",
  "negative_feedback_Text_Key": "Es tut uns leid, dass du nicht die Antwort bekommen hast, nach der du gesucht hast. Möchtest du uns helfen, uns zu verbessern?",
  "no_results_Text_Key": "Wir konnten keinen Treffer für deine Suchanfrage finden: {{query}}",
  "product_error_Text_Key": "Mit dieser Suchleiste kannst du Antworten auf Fragen finden. Produkte findest du über die Suchleiste oben. Möchtest du deine Bestellung einsehen und bearbeiten?",
  "read_more_button_Text_Key": "Mehr...",
  "results_Text_Key": "Wir haben {{number}} Ergebnis(se) gefunden für: {{query}}",
  "retrieving_Text_Key": "Wir montieren dein Suchergebnis...",
  "searchbar_Text_Key": "Suche",
  "searching_Text_Key": "Suche...",
  "showLess_Text_Key": "Weniger anzeigen",
  "showMore_Text_Key": "Mehr anzeigen",
  "spellcheck_Text_Key": "Versuch es mit anderen Suchbegriffen oder überprüfe, ob alles richtig geschrieben ist.",
  "submit_Text_Key": "Absenden",
  "try_again_Text_Key": "Bitte versuche es später noch einmal."
}