{
  "archived_Desc_Key": "Weitere Informationen findest du hier.",
  "archived_Redirection_Key": "customer-service/contact-us/",
  "archived_Title_Key": "Dieses Dokument wurde archiviert.",
  "carousel_Text_Key": "War der Inhalt nicht hilfreich für dich? Versuche eines der folgenden Dokumente.",
  "click_prompt_Text_Key": "Klicke hier",
  "close_Text_Key": "schließen",
  "customerService_Text_Key": "Kundenservice",
  "error_Text_Key": "Irgendetwas ist schief gelaufen",
  "feedback_Option1_Text_Key": "Der Text ist verwirrend.",
  "feedback_Option2_Text_Key": "Der Text ist für meine Frage nicht von Bedeutung.",
  "feedback_Option3_Text_Key": "Der Text ist relevant aber nicht hilfreich.",
  "feedback_Option4_Text_Key": "Der Text ist zu lang oder zu kurz.",
  "feedback_Question_1_Text_Key": "Welche Abschnitte unserer Antwort findest du mangelhaft oder nicht hilfreich?",
  "feedback_Rec_Text_Key": "Danke für dein Feedback!",
  "feedback_Text_Key": "War das hilfreich?",
  "here_Text_Key": "Hier",
  "knowledge_Text_Key": "Wissensinhalte",
  "link_prod_error_Text_Key": "https://www.ikea.com/de/de/customer-service/track-manage-order/",
  "loading_Text_Key": "Laden...",
  "market_Name_Key": "Deutschland",
  "modal_button_Close_Text_Key": "Schließen",
  "negative_feedback_Text_Key": "Es tut uns leid, dass du nicht die Antwort bekommen hast, nach der du gesucht hast. Möchtest du uns dabei helfen, uns zu verbessern?",
  "no_results_Text_Key": "Wir haben keine Ergebnisse für den Suchbegriff gefunden: {{query}}",
  "product_error_Text_Key": "Leider wurden keine Ergebnisse gefunden. Wenn du Produktinformationen benötigst nutze die Suchleiste weiter oben. Wenn du den Status deiner Bestellung überprüfen willst, klicke bitte hier.",
  "read_more_button_Text_Key": "Read more",
  "results_Text_Key": "Wir haben {{number}} Ergebnisse gefunden: {{query}}",
  "retrieving_Text_Key": "Abrufen von",
  "searchbar_Text_Key": "Gib ein Stichwort oder Thema ein, um eine Antwort zu finden.",
  "searching_Text_Key": "Suche...",
  "showLess_Text_Key": "Weniger anzeigen",
  "showMore_Text_Key": "Mehr anzeigen",
  "spellcheck_Text_Key": "Versuche andere Begriffe oder überprüfe, ob du alles richtig geschrieben hast.",
  "submit_Text_Key": "Absenden",
  "try_again_Text_Key": "Versuche es bitte später noch einmal."
}