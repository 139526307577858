import { KnowledgeComponent } from './components';
import register from 'preact-custom-element';
import './i18n';
import 'focus-visible';
import { langMapper } from './utils/Config';

const App = ({ lang }: { lang: string }) => {

  lang = langMapper(lang)
  
  return <KnowledgeComponent lang={lang} variant="standard"/>;
  
  //Keep below code for future A/B-tests
  //
  // let rand = Math.floor(Math.random() * 101);
  // if(rand >= 50){
  //   //@ts-ignore
  //   return <KnowledgeComponent lang={lang} variant="standard"/>;

  // }else{
  //   return <KnowledgeComponent lang={lang} variant="standard"/>;
  // } 
};

register(App, 'km-search-integration', ['lang'], { shadow: false });
