{
  "archived_Desc_Key": "Laguntza gehiago aurkituko duzu hemen",
  "archived_Title_Key": "Dokumentu hau artxibatu egin da.",
  "carousel_Text_Key": "Artikulu hau ez al zaizu lagungarria izan? Saiatu erlazionatutako artikulu hauetako bat.",
  "click_prompt_Text_Key": "hemen klikatu mesedez",
  "close_Text_Key": "Itxi",
  "customerService_Text_Key": "Bezeroarentzako Arreta",
  "error_Text_Key": "Zerbitzua ez dago erabilgarri",
  "feedback_Option1_Text_Key": "Testua ez da ondo ulertzen",
  "feedback_Option2_Text_Key": "Testuak ez dio nire galderari erantzuten",
  "feedback_Option3_Text_Key": "Testua nire galderari erantzuten dio, baina ez da lagungarria",
  "feedback_Option4_Text_Key": "Testua luzeegia edo laburregia da ",
  "feedback_Question_1_Text_Key": "Zure ustez, gure artikuluen zein zati dira desegokiak edo ez oso lagungarriak?",
  "feedback_Rec_Text_Key": "Eskerrik asko zure iritziagatik!",
  "feedback_Text_Key": "Baliagarria iruditu al zaizu?",
  "here_Text_Key": "hemen",
  "knowledge_Text_Key": "Ezagutza",
  "link_prod_error_Text_Key": "https://www.ikea.com/es/eu/customer-service/track-manage-order/",
  "loading_Text_Key": "Deskargatzen",
  "market_Name_Key": "Espainia",
  "modal_button_Close_Text_Key": "Itxi",
  "negative_feedback_Text_Key": "Sentitzen dugu bilatzen ari zinen erantzuna ez jaso izana, hobetzen lagundu nahi diguzu?",
  "no_results_Text_Key": "Ez da emaitzarik aurkitu eskatutako bilaketa terminoetarako: {{query}}",
  "product_error_Text_Key": "Ez dugu erresultadorik aurkitu, sentitzen dugu. Bilatzaite honek bat edo hainbeste hitz gakoak onartzen ditu. Gure artikuluei buruzko informazioa nahi baduzu, web-aren goialdean dagoen bilatzaile nagusia erabili ezazu. Zure eskariaren egoera kontsultatu nahi baduzu, ",
  "read_more_button_Text_Key": "Irakurri gehiago",
  "results_Text_Key": "{{number}} emaitza aurkitu dugu honetarako: {{query}}",
  "retrieving_Text_Key": "Berreskuratzen",
  "searchbar_Text_Key": "Hitz edo esaldi bat idatzi ezazu, eskaria edo aldatu adibidez.",
  "searching_Text_Key": "Bilatzen",
  "showLess_Text_Key": "Gutxiago erakuts",
  "showMore_Text_Key": "Gehiago ikusi",
  "spellcheck_Text_Key": "Saiatu ortografia egiaztatzen edo gako-hitzak erabiltzen",
  "submit_Text_Key": "Bidali",
  "try_again_Text_Key": "Mesedez, saiatu berriro geroago"
}