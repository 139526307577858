{
  "archived_Desc_Key": "Pode encontrar mais ajuda aqui",
  "archived_Title_Key": "Este documento foi arquivado.",
  "carousel_Text_Key": "O artigo não foi útil? Veja um dos artigos relacionados abaixo.",
  "click_prompt_Text_Key": "aqui",
  "close_Text_Key": "Fechar",
  "customerService_Text_Key": "Apoio ao Cliente",
  "error_Text_Key": "Alguma coisa correu mal",
  "feedback_Option1_Text_Key": "O texto é confuso",
  "feedback_Option2_Text_Key": "O texto não é relevante para a minha pergunta",
  "feedback_Option3_Text_Key": "O texto é relevante, mas não é útil",
  "feedback_Option4_Text_Key": "O texto é demasiado longo ou demasiado curto ",
  "feedback_Question_1_Text_Key": "Que partes específicas dos nossos artigos considera pouco satisfatórias ou inúteis?",
  "feedback_Rec_Text_Key": "Agradecemos a sua opinião!",
  "feedback_Text_Key": "Esta informação foi útil?",
  "here_Text_Key": "aqui",
  "knowledge_Text_Key": "Saber mais",
  "link_prod_error_Text_Key": "https://www.ikea.com/pt/pt/customer-service/",
  "loading_Text_Key": "A carregar",
  "market_Name_Key": "Portugal",
  "modal_button_Close_Text_Key": "Fechar",
  "negative_feedback_Text_Key": "Lamentamos que não tenha obtido a resposta que procurava. Gostaria de nos ajudar a melhorar?",
  "no_results_Text_Key": "Não encontrámos nenhum resultado para os termos da pesquisa: {{query}}",
  "product_error_Text_Key": "Lamentamos, mas não foram encontrados resultados. Este motor de pesquisa suporta apenas uma ou mais palavras-chave. Se quiser informações sobre os nossos produtos, use o motor de busca principal localizado na parte superior da web. Se pretender verificar o estado da sua encomenda, clique.",
  "read_more_button_Text_Key": "Ler mais",
  "results_Text_Key": "Encontrámos {{number}} resultado(s) para: {{query}}",
  "retrieving_Text_Key": "A obter informação",
  "searchbar_Text_Key": "Escreva uma palavra ou frase, exemplo encomendar ou alterar.",
  "searching_Text_Key": "A pesquisar...",
  "showLess_Text_Key": "Mostre menos",
  "showMore_Text_Key": "Mostrar mais",
  "spellcheck_Text_Key": "Verifique os termos da pesquisa ou, em alternativa para obter suporte, poderá contactar-nos através do chat.",
  "submit_Text_Key": "Enviar",
  "try_again_Text_Key": "Por favor tente novamente mais tarde"
}