import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import InlineMessage from '@ingka/inline-message';

import { useTranslation } from 'react-i18next';
import {langStore} from '../../utils/translationObject'

import '@ingka/svg-icon/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import '@ingka/inline-message/dist/style.css';
import "@ingka/focus/dist/style.css";

const CalculateVariant = (errorCode: number) => {
  let firstChar: string | number = errorCode.toString();
  firstChar = firstChar.substr(0, 1);
  firstChar = parseInt(firstChar);

  if (firstChar === 5 || firstChar === 4) {
    return 'negative';
  } else {
    return 'cautionary';
  }
};

interface IErrorCardProps {
  message: string;
  errorCode?: number;
  lang:string;
}

const ErrorCard: React.FC<IErrorCardProps> = ({
  message = '',
  errorCode = 0,
  lang='en_NL'
}) => {
  const { t } = useTranslation();

  const renderFunc = (errorCode: number)  => {
    
    const langKey: keyof typeof langStore = lang as any;

    if(errorCode && errorCode > 0 && errorCode !== 22){
      return(
        <InlineMessage
          variant={CalculateVariant(errorCode)}
          title={langStore[langKey].error_Text_Key}
          body={message}
        />
      );

    } else if( errorCode && errorCode === 22){
      return (
        <InlineMessage
          variant="cautionary"
          title={langStore[langKey].product_error_Text_Key}
          linkLabel={langStore[langKey].click_prompt_Text_Key}
          link={langStore[langKey].link_prod_error_Text_Key}
        />
      );
    } else {
      return (
        <InlineMessage
          variant="cautionary"
          title={message}
          body={langStore[langKey].spellcheck_Text_Key}
        />
      );
    }
  }

  return renderFunc(errorCode);
};

export default ErrorCard;
