{
  "archived_Desc_Key": "Weitere Informationen findest du hier",
  "archived_Title_Key": "Dieses Dokument wurde archiviert.",
  "carousel_Text_Key": "War dieses Dokument nicht hilfreich für dich? Probiere eines der folgenden Dokumente aus.",
  "click_prompt_Text_Key": "hier",
  "close_Text_Key": "Schliessen",
  "customerService_Text_Key": "Kundenservice",
  "error_Text_Key": "Etwas hat nicht funktioniert",
  "feedback_Option1_Text_Key": "Der Text ist verwirrend zu verstehen",
  "feedback_Option2_Text_Key": "Der Text ist für meine Frage nicht relevant",
  "feedback_Option3_Text_Key": "Der Text ist relevant, aber nicht hilfreich",
  "feedback_Option4_Text_Key": "Der Text ist zu lang oder zu kurz ",
  "feedback_Question_1_Text_Key": "Welche Teile unserer Artikel findest du mangelhaft oder nicht hilfreich?",
  "feedback_Rec_Text_Key": "Danke für dein Feedback!",
  "feedback_Text_Key": "War dieser Beitrag hilfreich?",
  "here_Text_Key": "hier",
  "knowledge_Text_Key": "Enzyklopädie",
  "link_prod_error_Text_Key": "https://www.ikea.com/ch/de/customer-service/track-manage-order/",
  "loading_Text_Key": "Lädt",
  "market_Name_Key": "Schweiz",
  "modal_button_Close_Text_Key": "Schliessen",
  "negative_feedback_Text_Key": "Es tut uns leid, dass du nicht die Antwort bekommen hast, nach der du gesucht hast. Möchtest du uns dabei helfen, uns zu verbessern?",
  "no_results_Text_Key": "Es wurden keine Ergebnisse für deine angegebenen Suchwörter gefunden: {{query}}",
  "product_error_Text_Key": "Leider wurden keine Ergebnisse gefunden. Diese Suchmaschine unterstützt nur einen oder mehrere Suchbegriffe. Wenn du Informationen über unsere Produkte suchst, verwende die Suchfunktion im oberen Bereich der Website. Wenn du den Status deiner Bestellung überprüfen willst, klicke bitte hier.",
  "read_more_button_Text_Key": "Mehr erfahren",
  "results_Text_Key": "Wir haben {{number}} Ergebnisse für {{query}} gefunden",
  "retrieving_Text_Key": "Am Abrufen",
  "searchbar_Text_Key": "z.B.: Lieferung, Rückgabe (Keine Nummer)",
  "searching_Text_Key": "Am Suchen...",
  "showLess_Text_Key": "Weniger anzeigen",
  "showMore_Text_Key": "Showing",
  "spellcheck_Text_Key": "Probier mal, die Rechtschreibung zu überprüfen oder andere Schlüsselwörter zu verwenden",
  "submit_Text_Key": "Senden",
  "try_again_Text_Key": "Bitte versuche es später noch einmal"
}