{
  "archived_Desc_Key": "Puteți găsi aici ajutor suplimentar.",
  "archived_Title_Key": "Acest document a fost arhivat.",
  "carousel_Text_Key": "Articolul nu a fost util? Încearcă unul dintre articolele similare de mai jos.",
  "click_prompt_Text_Key": "click aici",
  "close_Text_Key": "Închide",
  "customerService_Text_Key": "Relații clienți",
  "error_Text_Key": "A apărut o eroare",
  "feedback_Option1_Text_Key": "Textul este derutant",
  "feedback_Option2_Text_Key": "Textul nu este relevant pentru întrebarea mea",
  "feedback_Option3_Text_Key": "Textul este relevant, dar nu este util",
  "feedback_Option4_Text_Key": "Textul este prea lung sau prea scurt ",
  "feedback_Question_1_Text_Key": "Ce părți ale articolului nostru ți se par nesatisfăcătoare sau nefolositoare?",
  "feedback_Rec_Text_Key": "Îți mulțumim pentru feedback!",
  "feedback_Text_Key": "Ți s-a părut utilă informația?",
  "here_Text_Key": "aici",
  "knowledge_Text_Key": "Informații",
  "link_prod_error_Text_Key": "https://www.ikea.com/ro/ro/customer-service/track-manage-order/",
  "loading_Text_Key": "Încărcare",
  "market_Name_Key": "România",
  "modal_button_Close_Text_Key": "Închide",
  "negative_feedback_Text_Key": "Ne pare rău că nu ai primit răspunsul pe care îl căutai, dorești să ne ajuți să ne îmbunătățim?",
  "no_results_Text_Key": "Nu am găsit niciun rezultat pentru termenul căutat: {{query}}",
  "product_error_Text_Key": "Dacă încerci să găsești un produs, te rog să folosești bara de căutare din partea de sus a paginii. Dacă dorești să afli informații despre comanda ta, te rog fă",
  "read_more_button_Text_Key": "Citește mai mult",
  "results_Text_Key": "Noi am găsit {{number}} rezultat pentru: {{query}}",
  "retrieving_Text_Key": "Pridobivanje",
  "searchbar_Text_Key": "Exemplu de căutare: livrare sau factură",
  "searching_Text_Key": "Căutare...",
  "showLess_Text_Key": "Arată mai puțin",
  "showMore_Text_Key": "Arată mai mult",
  "spellcheck_Text_Key": "Te rugăm să verifici că ai scris corect sau să încerci alte cuvinte cheie",
  "submit_Text_Key": "Trimite",
  "try_again_Text_Key": "Te rugăm să încerci din nou mai târziu"
}