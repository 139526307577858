import { useState, useEffect, useCallback } from 'react';
declare global {
  interface Window {
    ikea: any;
    ga: any;
    sendEvent: (object: TWindowObject) => null;
  }
}

type TWindowObject = {
  event_category: string;
  event_action: string;
  event_label: string;
  // custom: TCustomAnalyticsObject;
};

type TCustomAnalyticsObject = {
  veta_guid?: string;
  position?: number;
  veta_top_articles?: [string];
  query?: string;
  //Will be handled by middle-layer message + reaction.
  message?: string;
  reaction?: boolean;
  veta_results?: number;
};

const useAnalytics = () => {
  const [loaded, setLoaded] = useState(false);
  const consent = window.ikea?.cookieConsent?.hasConsent(2);
  useEffect(() => {
    if (!canUseGA) {
      // throw Error('Analytics library not loaded');
      console.warn('Analytics library not loaded: will not send analytics');
    } else {
      console.log('Analytics library loaded');
    }
  }, [consent]);

  const send = useCallback(
    (eventName: string) => {
      if (canUseGA || consent) {
        send(eventName)
      }
    },
    [consent]
  );
  return {
    loaded,
    send,
  };
};
const canUseGA = (): boolean => {
  if (window.ikea?.cookieConsent?.hasConsent(2)) {
    return true;
  }
  return false;
};
interface ISendGA {
  results?: number;
  query?: string;
  guid?: string;
  action: string;
  variant: 'standard';
  pos?: number;
  page?: number;
  topArts?: [string];
  loadTimeMs?: number;
  backend?: string;
  correlationId?: string;
  activity?: string
  articlesDisplayed?: number,
  redirect?: string;
}

export const sendGA = ({
  results,
  query,
  guid,
  action,
  pos,
  variant,
  page,
  topArts,
  loadTimeMs,
  backend,
  correlationId,
  activity,
  articlesDisplayed,
  redirect
}: ISendGA) => {
  if (canUseGA()) {
    let analyticsObject = null;
    switch (action) {
      //pass along a collection of id's of the first five articles.
      case 'search': {
        analyticsObject = {
          event_category: 'customer_service',
          event_action: 'vetaInfoReq',
          event_label: JSON.stringify({
            component: 'search',
            variant,
            results,
            query,
            load_time_ms: loadTimeMs,
            backend: backend,
            correlationId: correlationId,
            activity: action,
            redirect
          }),
        };
        break;
      }
      case 'articleOpen': {
        analyticsObject = {
          event_category: 'customer_service',
          event_action: 'vetaInfoReq',
          event_label: JSON.stringify({
            component: 'search',
            guid,
            variant,
            pos: pos + 1,
            query,
            correlationId,
            activity: 'readMoreArticle'
          }),
        };
        break;
      }
      default:
        break;
    }

    if (analyticsObject) {
      window.sendEvent(analyticsObject);
    }
    //@ts-ignore
  } else {
    console.log('Unable to sent GA event');
  }
};

interface ISendFeedback {
  reaction: boolean;
  docGuid: string | null;
  variant: 'standard'
}

export const sendFeedback = ({ reaction, docGuid, variant }: ISendFeedback) => {
  if (canUseGA() && docGuid) {
    let analyticsObject = null;

    analyticsObject = {
      event_category: 'customer_service',
      event_action: 'feedback',
      event_label: JSON.stringify({
        component: 'search',
        guid: docGuid,
        variant,
        reaction: reaction ? 'positive' : 'negative',
        message: '',
      }),
    };

    if (analyticsObject) {
      window.sendEvent(analyticsObject);
    }
    //@ts-ignore
  } else {
    console.log('not allowed ga event');
  }
};

export default useAnalytics;
