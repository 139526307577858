{
  "archived_Desc_Key": "Dalsze wsparcie znajdziesz tutaj.",
  "archived_Title_Key": "Ten dokument został zarchiwizowany.",
  "carousel_Text_Key": "Ten artykuł nie był pomocny? Sprawdź inne artykuły poniżej.",
  "click_prompt_Text_Key": "kliknij tutaj",
  "close_Text_Key": "Zamknij",
  "customerService_Text_Key": "Obsługa klienta",
  "error_Text_Key": "Coś poszło nie tak",
  "feedback_Option1_Text_Key": "Zrozumienie tekstu jest mylące",
  "feedback_Option2_Text_Key": "Tekst nie odpowiada mojemu pytaniu",
  "feedback_Option3_Text_Key": "Tekst jest trafny, ale nie pomocny",
  "feedback_Option4_Text_Key": "Tekst jest za długi lub za krótki",
  "feedback_Question_1_Text_Key": "Które części naszych artykułów uważasz za niezadowalające lub nieprzydatne?",
  "feedback_Rec_Text_Key": "Dziękujemy za Twoją opinię!",
  "feedback_Text_Key": "Czy powyższa informacja okazała się być pomocna?",
  "here_Text_Key": "tutaj.",
  "knowledge_Text_Key": "Wiedza",
  "link_prod_error_Text_Key": "https://www.ikea.com/pl/pl/customer-service/track-manage-order/",
  "loading_Text_Key": "Ładowanie",
  "market_Name_Key": "Polska",
  "modal_button_Close_Text_Key": "Zamknij",
  "negative_feedback_Text_Key": "Przykro nam, że nie otrzymałeś odpowiedzi, której szukałeś. Czy chcesz nam pomóc ją poprawić?",
  "no_results_Text_Key": "Nie znaleźliśmy wyników dla zapytania: {{query}}",
  "product_error_Text_Key": "Brak wyników dla... \b Ta wyszukiwarka służy jedynie do wyszukiwania artykułów pomocniczych. Jeśli szukasz naszego produktu, wpisz je jego numer w górnym pasku wyszukiwania na stronie IKEA.pl Jeśli szukasz informacji o swoim zamówieniu,",
  "read_more_button_Text_Key": "Więcej",
  "results_Text_Key": "Wyniki znalezione dla zapytania \"{{query}}\": {{number}}",
  "retrieving_Text_Key": "Odzyskiwanie",
  "searchbar_Text_Key": "Tu wpisz hasło lub krótkie pytanie",
  "searching_Text_Key": "Wyszukiwanie...",
  "showLess_Text_Key": "Pokaż mniej",
  "showMore_Text_Key": "Pokaż więcej",
  "spellcheck_Text_Key": "Sprawdź pisownię lub spróbuj użyć innych słów.",
  "submit_Text_Key": "Prześlij",
  "try_again_Text_Key": "Spróbuj ponownie później"
}