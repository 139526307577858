"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireWildcard(require("react"));
var _reactFocusLock = _interopRequireDefault(require("react-focus-lock"));
var _classnames = _interopRequireDefault(require("classnames"));
var _modalShared = require("@ingka/modal-shared");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const modalTypes = ['Sheets', 'Prompt', 'Theatre'];
const elx = function () {
  for (var _len = arguments.length, listeners = new Array(_len), _key = 0; _key < _len; _key++) {
    listeners[_key] = arguments[_key];
  }
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    return listeners.map(l => l && l(...args));
  };
};
const Modal = _ref => {
  let {
    className,
    visible,
    prefix,
    children,
    handleCloseBtn,
    focusLockProps,
    keepContentMounted,
    escapable,
    onModalClosed,
    onModalOpened,
    scrollLock,
    ...other
  } = _ref;
  const [isOpen, setIsOpen] = _react.default.useState(visible);
  const [animationMountHelper, setAnimationMountHelper] = _react.default.useState(false);
  const animationFrameRef = _react.default.useRef(-1);
  const baseClass = `${prefix}modal-wrapper`;
  (0, _react.useEffect)(() => {
    (0, _modalShared.onVisibleChanged)(prefix, visible, scrollLock, isOpen, setIsOpen, setAnimationMountHelper);
  }, [visible]);
  (0, _react.useEffect)(() => {
    if (visible) (0, _modalShared.enter)(setIsOpen, scrollLock, prefix);
    return () => {
      if (scrollLock) (0, _modalShared.releaseBody)(prefix);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []);
  const overlayStyle = (0, _classnames.default)(baseClass, isOpen ? `${baseClass}--open` : `${baseClass}--close`, className);
  const backdropStyle = (0, _classnames.default)(`${baseClass}__backdrop`, `${baseClass}__backdrop--dark`);
  const hookedModal = _react.default.Children.map(children, child => _react.default.cloneElement(child, {
    open: isOpen,
    closeBtnClick: handleCloseBtn,
    prefix
  }));
  return _react.default.createElement("div", _extends({}, other, {
    onKeyDown: e => {
      elx(other.onKeyDown, (0, _modalShared.escFunction)(e, baseClass, escapable, handleCloseBtn, setIsOpen, setAnimationMountHelper));
    },
    className: overlayStyle,
    tabIndex: "-1"
  }), _react.default.createElement("div", {
    className: backdropStyle,
    onMouseDown: e => {
      (0, _modalShared.onBackdropClick)(e, escapable, handleCloseBtn);
    },
    onAnimationEnd: e => {
      (0, _modalShared.handleAnimationEnd)(e, animationFrameRef.current, isOpen, onModalOpened, onModalClosed, scrollLock, animationMountHelper, setAnimationMountHelper, prefix);
    }
  }), (keepContentMounted || isOpen || animationMountHelper) && _react.default.createElement(_reactFocusLock.default, _extends({
    returnFocus: true,
    autoFocus: true
  }, focusLockProps, {
    children: hookedModal
  })));
};
Modal.defaultProps = {
  prefix: '',
  focusLockProps: {},
  keepContentMounted: false,
  escapable: true,
  scrollLock: true
};
var _default = Modal;
exports.default = _default;