{
  "archived_Desc_Key": "Per maggiori informazioni, clicca qui.",
  "archived_Title_Key": "Questo documento è stato archiviato.",
  "carousel_Text_Key": "L'articolo non è stato utile? Prova a leggere uno dei documenti correlati qui sotto.",
  "click_prompt_Text_Key": "qui",
  "close_Text_Key": "Chiudere",
  "customerService_Text_Key": "Servizio clienti",
  "error_Text_Key": "Qualcosa non ha funzionato",
  "feedback_Option1_Text_Key": "Il testo è difficile da capire",
  "feedback_Option2_Text_Key": "Il testo non è pertinente alla mia domanda",
  "feedback_Option3_Text_Key": "Il testo è pertinente ma non utile",
  "feedback_Option4_Text_Key": "Il testo è troppo lungo o troppo corto ",
  "feedback_Question_1_Text_Key": "Quali parti particolari dei nostri documenti trovi insoddisfacenti o poco utili?",
  "feedback_Rec_Text_Key": "Grazie per il tuo feedback!",
  "feedback_Text_Key": "Ti è stato utile?",
  "here_Text_Key": "qui",
  "knowledge_Text_Key": "Enciclopedia",
  "link_prod_error_Text_Key": "https://www.ikea.com/ch/it/customer-service/track-manage-order/",
  "loading_Text_Key": "Caricamento",
  "market_Name_Key": "Svizzera",
  "modal_button_Close_Text_Key": "Chiudere",
  "negative_feedback_Text_Key": "Ci dispiace che tu non abbia trovato la risposta che cercavi, vuoi aiutarci a migliorare?",
  "no_results_Text_Key": "Non è stato trovato nessun risultato di ricerca per il seguente termine: {{query}}",
  "product_error_Text_Key": "Spiacente, non sono stati trovati risultati. Questo motore di ricerca supporta solo una o più parole chiave. Se desideri informazioni sui nostri prodotti, utilizza il motore di ricerca principale che si trova nella parte superiore del sito. Se vuoi controllare lo stato del tuo ordine, clicca qui.",
  "read_more_button_Text_Key": "Per saperne di più",
  "results_Text_Key": "Abbiamo trovato {{number}} risultati per: {{query}}",
  "retrieving_Text_Key": "Recuperare",
  "searchbar_Text_Key": "Es.: consegna, reso (nessun numero)",
  "searching_Text_Key": "Sta cercando...",
  "showLess_Text_Key": "Mostra di meno",
  "showMore_Text_Key": "Mostra di più",
  "spellcheck_Text_Key": "Prova a controllare l'ortografia o a usare parole chiave diverse.",
  "submit_Text_Key": "Invia",
  "try_again_Text_Key": "Ti preghiamo di riprovare più tardi"
}