import * as en_DE from "../translations/en-DE.json";
import * as es_US from "../translations/es-US.json";
import * as ca_ES from "../translations/ca-ES.json";
import * as cs_CZ from "../translations/cs-CZ.json";
import * as da_DK from "../translations/da-DK.json";
import * as de_AT from "../translations/de-AT.json";
import * as de_CH from "../translations/de-CH.json";
import * as de_DE from "../translations/de-DE.json";
import * as en_AT from "../translations/en-AT.json";
import * as en_AU from "../translations/en-AU.json";
import * as en_BE from "../translations/en-BE.json";
import * as en_CA from "../translations/en-CA.json";
import * as en_CH from "../translations/en-CH.json";
import * as en_CZ from "../translations/en-CZ.json";
import * as en_ES from "../translations/en-ES.json";
import * as en_FI from "../translations/en-FI.json";
import * as en_GB from "../translations/en-GB.json";
import * as en_IE from "../translations/en-IE.json";
import * as en_IN from "../translations/en-IN.json";
import * as en_JP from "../translations/en-JP.json";
import * as en_NL from "../translations/en-NL.json";
import * as en_PT from "../translations/en-PT.json";
import * as en_SE from "../translations/en-SE.json";
import * as en_US from "../translations/en-US.json";
import * as es_ES from "../translations/es-ES.json";
import * as eu_ES from "../translations/eu-ES.json";
import * as fi_FI from "../translations/fi-FI.json";
import * as fr_BE from "../translations/fr-BE.json";
import * as fr_CA from "../translations/fr-CA.json";
import * as fr_CH from "../translations/fr-CH.json";
import * as fr_FR from "../translations/fr-FR.json";
import * as gl_ES from "../translations/gl-ES.json";
import * as hr_HR from "../translations/hr-HR.json";
import * as hu_HU from "../translations/hu-HU.json";
import * as it_CH from "../translations/it-CH.json";
import * as it_IT from "../translations/it-IT.json";
import * as ja_JP from "../translations/ja-JP.json";
import * as ko_KR from "../translations/ko-KR.json";
import * as nb_NO from "../translations/nb-NO.json";
import * as nl_BE from "../translations/nl-BE.json";
import * as nl_NL from "../translations/nl-NL.json";
import * as pl_PL from "../translations/pl-PL.json";
import * as pt_PT from "../translations/pt-PT.json";
import * as ro_RO from "../translations/ro-RO.json";
import * as sk_SK from "../translations/sk-SK.json";
import * as sl_SI from "../translations/sl-SI.json";
import * as sr_RS from "../translations/sr-RS.json";
import * as sv_SE from "../translations/sv-SE.json";
import * as uk_UA from "../translations/uk-UA.json";


export const langStore = {
'ca-ES':ca_ES,
'cs-CZ':cs_CZ,
'da-DK':da_DK,
'de-AT':de_AT,
'de-CH':de_CH,
'de-DE':de_DE,
'en-AT':en_AT,
'en-AU':en_AU,
'en-BE':en_BE,
'en-CA':en_CA,
'en-CH':en_CH,
'en-CZ':en_CZ,
'en-DE':en_DE,
'en-ES':en_ES,
'en-FI':en_FI,
'en-GB':en_GB,
'en-IE':en_IE,
'en-IN':en_IN,
'en-JP':en_JP,
'en-NL':en_NL,
'en-PT':en_PT,
'en-SE':en_SE,
'en-US':en_US,
'es-ES':es_ES,
'es-US':es_US,
'eu-ES':eu_ES,
'fi-FI':fi_FI,
'fr-BE':fr_BE,
'fr-CA':fr_CA,
'fr-CH':fr_CH,
'fr-FR':fr_FR,
'gl-ES':gl_ES,
'hr-HR':hr_HR,
'hu-HU':hu_HU,
'it-CH':it_CH,
'it-IT':it_IT,
'ja-JP':ja_JP,
'ko-KR':ko_KR,
'no-NO':nb_NO,
'nl-BE':nl_BE,
'nl-NL':nl_NL,
'pl-PL':pl_PL,
'pt-PT':pt_PT,
'ro-RO':ro_RO,
'sk-SK':sk_SK,
'sl-SI':sl_SI,
'sr-RS':sr_RS,
'sv-SE':sv_SE,
'uk-UA':uk_UA
};