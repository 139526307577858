"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _propTypes = require("prop-types");
var _react = _interopRequireDefault(require("react"));
var _classnames = _interopRequireDefault(require("classnames"));
var _headerPropTypeValidation = _interopRequireDefault(require("../../utils/headerPropTypeValidation"));
var _AutoFocusOnMount = _interopRequireDefault(require("../../utils/AutoFocusOnMount"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Sheets = _ref => {
  let {
    className,
    fullSize,
    prefix,
    children,
    open,
    closeBtnClick,
    header,
    alignment,
    preserveAlignment,
    footer,
    size,
    describedById,
    labelledById,
    ariaLabel,
    ...other
  } = _ref;
  const classes = (0, _classnames.default)(`${prefix}sheets`, `${prefix}sheets--${size}`, fullSize && `${prefix}sheets--mobile-full-size`, alignment === 'left' && `${prefix}sheets--mirrored`, preserveAlignment && `${prefix}sheets--preserve-alignment`, open ? `${prefix}sheets--enter` : `${prefix}sheets--close`, className);
  const hookedHeader = header ? _react.default.cloneElement(header, {
    closeBtnClick,
    prefix
  }) : null;
  const hookedFooter = footer && _react.default.cloneElement(footer, {
    prefix,
    closeBtnClick
  }) || null;
  const hookedBody = _react.default.Children.map(children, child => child.props ? _react.default.cloneElement(child, {
    prefix
  }) : child);
  const [wrapperFocus, contentRef] = (0, _AutoFocusOnMount.default)();
  return _react.default.createElement("div", _extends({
    "aria-describedby": describedById,
    "aria-labelledby": labelledById,
    "aria-label": ariaLabel
  }, other, {
    className: classes,
    "aria-modal": "true",
    role: "dialog",
    tabIndex: "-1"
  }), hookedHeader && !hookedHeader.props.floating && hookedHeader, _react.default.createElement("div", {
    className: `${prefix}sheets__content-wrapper`,
    role: "document",
    tabIndex: "0",
    ref: contentRef,
    "data-autofocus": wrapperFocus
  }, _react.default.createElement("div", null, " ", hookedHeader && hookedHeader.props.floating && hookedHeader, hookedBody)), hookedFooter);
};
Sheets.defaultProps = {
  open: false,
  prefix: '',
  size: 'small',
  alignment: 'right',
  preserveAlignment: false
};
var _default = Sheets;
exports.default = _default;