{
  "archived_Desc_Key": "Puedes encontrar más ayuda aquí",
  "archived_Redirection_Key": "atención al cliente/contáctanos/",
  "archived_Title_Key": "Este documento ha sido archivado",
  "carousel_Text_Key": "¿El artículo no te ha resultado útil? Prueba uno de los artículos relacionados a continuación.",
  "click_prompt_Text_Key": "aquí",
  "close_Text_Key": "Cerrar",
  "customerService_Text_Key": "Atención al cliente",
  "error_Text_Key": "Algo salió mal",
  "feedback_Option1_Text_Key": "El texto es confuso de entender",
  "feedback_Option2_Text_Key": "El texto no es relevante para mi pregunta",
  "feedback_Option3_Text_Key": "El texto es relevante pero no útil",
  "feedback_Option4_Text_Key": "El texto es demasiado largo o demasiado corto ",
  "feedback_Question_1_Text_Key": "¿Qué partes concretas de nuestros artículos te parecen insatisfactorias o poco útiles?",
  "feedback_Rec_Text_Key": "¡Gracias por tu opinión!",
  "feedback_Text_Key": "¿Te resultó útil?",
  "here_Text_Key": "aquí",
  "knowledge_Text_Key": "Conocimientos",
  "link_prod_error_Text_Key": "https://www.ikea.com/us/es/customer-service/track-manage-order",
  "loading_Text_Key": "Cargando",
  "market_Name_Key": "Reino Unido",
  "modal_button_Close_Text_Key": "Cerrar",
  "negative_feedback_Text_Key": "Sentimos que no hayas obtenido la respuesta que buscabas, ¿te gustaría ayudarnos a mejorar?",
  "no_results_Text_Key": "No hay resultados para tu búsqueda: {{query}}",
  "product_error_Text_Key": "Lo sentimos, no hemos encontrado resultados. Este buscador solo permite una o más palabras claves. Si deseas información sobre nuestros productos, utiliza el buscador principal ubicado en la parte superior de la página. Si deseas consultar el estado de tu pedido, por favor, haz clic.",
  "read_more_button_Text_Key": "Más información",
  "results_Text_Key": "Hemos encontrado {{number}} resultado(s) para: {{query}}",
  "retrieving_Text_Key": "Recuperando datos",
  "searchbar_Text_Key": "Escribe una palabra o frase, por ejemplo, pedido o modificar.",
  "searching_Text_Key": "Buscando...",
  "showLess_Text_Key": "Ver menos",
  "showMore_Text_Key": "Ver más",
  "spellcheck_Text_Key": "Intenta revisar la ortografía o usar otras palabras.",
  "submit_Text_Key": "Enviar",
  "try_again_Text_Key": "Por favor, inténtalo de nuevo más tarde"
}