{
  "archived_Desc_Key": "Další nápovědu naleznete zde",
  "archived_Title_Key": "Tento dokument byl archivován.",
  "carousel_Text_Key": "Nebyl tento článek dostatečně užitečný? Vyzkoušejte podobný obsah zobrazený níže.",
  "click_prompt_Text_Key": "Klikněte zde",
  "close_Text_Key": "Zavřít",
  "customerService_Text_Key": "Zákaznicky servis",
  "error_Text_Key": "Něco se nepovedlo",
  "feedback_Option1_Text_Key": "Článek je napsaný nesrozumitelně.",
  "feedback_Option2_Text_Key": "Článek neposkytuje relevantní odpověď na mou otázku.",
  "feedback_Option3_Text_Key": "Článek odpovídá na moji otázku, ale nepomohl mi.",
  "feedback_Option4_Text_Key": "Článek je příliš dlouhý/krátký.",
  "feedback_Question_1_Text_Key": "Co přesně bylo v článku špatně?",
  "feedback_Rec_Text_Key": "Děkujeme za vaši zpětnou vazbu",
  "feedback_Text_Key": "Bylo to pro vás užitečné?",
  "here_Text_Key": "zde",
  "knowledge_Text_Key": "Centrum znalostí",
  "link_prod_error_Text_Key": "https://www.ikea.com/cz/cs/customer-service/track-manage-order/",
  "loading_Text_Key": "Načítám",
  "market_Name_Key": "Česká republika",
  "modal_button_Close_Text_Key": "Zavřít",
  "negative_feedback_Text_Key": "Je nám líto, že jste nedostali odpověď, kterou jste hledali, chcete nám pomoci se zlepšením?",
  "no_results_Text_Key": "Pro hledaný výraz nebyly nalezeny žádné výsledky: {{query}}",
  "product_error_Text_Key": "Toto pole slouží pro vyhledávání nápovědy. Pokud chcete hledat výrobky na základě jejich čísla, použijte hlavní vyhledávací pole na IKEA.cz.",
  "read_more_button_Text_Key": "Přečtěte si více",
  "results_Text_Key": "Našli jsme {{number}} výsledek/výsledků pro: {{query}}",
  "retrieving_Text_Key": "Načítám",
  "searchbar_Text_Key": "Napište, co hledáte",
  "searching_Text_Key": "Vyhledávám...",
  "showLess_Text_Key": "Zobrazit méně",
  "showMore_Text_Key": "Zobrazit více",
  "spellcheck_Text_Key": "Zkuste zkontrolovat pravopis nebo použít jiná klíčová slova",
  "submit_Text_Key": "Odeslat",
  "try_again_Text_Key": "Prosím zkuste to znovu později"
}