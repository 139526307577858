{
  "archived_Desc_Key": "Vous trouverez plus d'informations en cliquant ici.",
  "archived_Title_Key": "Ce document a été supprimé.",
  "carousel_Text_Key": "Cette information n'était pas utile? Essayez l'un des documents ci-dessous",
  "click_prompt_Text_Key": "cliquez ici",
  "close_Text_Key": "Fermer",
  "customerService_Text_Key": "Service à la clientèle",
  "error_Text_Key": "Quelque chose ne va pas",
  "feedback_Option1_Text_Key": "Le texte est difficile à comprendre",
  "feedback_Option2_Text_Key": "Le texte n’est pas pertinent par rapport à ma question",
  "feedback_Option3_Text_Key": "Le texte est pertinent, mais il n’est pas utile",
  "feedback_Option4_Text_Key": "Le texte est trop long ou trop court ",
  "feedback_Question_1_Text_Key": "Quels éléments particuliers de nos articles trouvez-vous insatisfaisants ou inutiles ?",
  "feedback_Rec_Text_Key": "Merci pour votre retour",
  "feedback_Text_Key": "Avez-vous trouvé cette information utile?",
  "here_Text_Key": "ici.",
  "knowledge_Text_Key": "Base de connaissance",
  "link_prod_error_Text_Key": "https://www.ikea.com/ca/fr/customer-service/track-manage-order/",
  "loading_Text_Key": "Chargement",
  "market_Name_Key": "Canada",
  "modal_button_Close_Text_Key": "Fermer",
  "negative_feedback_Text_Key": "Nous sommes désolés que vous n’ayez pas obtenu la réponse que vous cherchiez, souhaitez-vous nous aider à nous améliorer ?",
  "no_results_Text_Key": "Aucun résultat n’a été trouvé avec vos termes de recherche! {{query}}",
  "product_error_Text_Key": "Cette barre de recherche sert uniquement aux documents d'information. Pour les numéros d'article, veuillez les taper dans la barre de recherche en haut de la page. Pour un suivi de votre commande,",
  "read_more_button_Text_Key": "En savoir plus",
  "results_Text_Key": "Nous avons trouvé {{number}} résultat(s) pour: {{query}}",
  "retrieving_Text_Key": "Récupération",
  "searchbar_Text_Key": "Posez votre question ici.",
  "searching_Text_Key": "Recherche en cours de…",
  "showLess_Text_Key": "Afficher moins",
  "showMore_Text_Key": "Afficher plus",
  "spellcheck_Text_Key": "Vérifiez l’orthographe ou utilisez d’autres mots clef",
  "submit_Text_Key": "Envoyer",
  "try_again_Text_Key": "Merci réessayer ultérieurement"
}