{
  "archived_Desc_Key": "Trobarà més ajuda aquí",
  "archived_Title_Key": "Aquest document ha estat arxivat.",
  "carousel_Text_Key": "No li ha resultat útil aquest article? Provi amb un dels següents articles relacionats.",
  "click_prompt_Text_Key": "aqui",
  "close_Text_Key": "Tanca",
  "customerService_Text_Key": "Atenció al client",
  "error_Text_Key": "Alguna cosa ha anat malament",
  "feedback_Option1_Text_Key": "El text és confús d'entendre",
  "feedback_Option2_Text_Key": "El text no és rellevant per a la meva pregunta",
  "feedback_Option3_Text_Key": "El text és rellevant però no és útil",
  "feedback_Option4_Text_Key": "El text és massa llarg o massa curt ",
  "feedback_Question_1_Text_Key": "Quines parts dels nostres articles trobes que no són satisfactòries o no són útils?",
  "feedback_Rec_Text_Key": "Gràcies per la teva opinió!",
  "feedback_Text_Key": "T'ha resultat útil?",
  "here_Text_Key": "aquí",
  "knowledge_Text_Key": "Coneixement",
  "link_prod_error_Text_Key": "https://www.ikea.com/es/ca/customer-service/track-manage-order/",
  "loading_Text_Key": "S´està carregant",
  "market_Name_Key": "Espanya",
  "modal_button_Close_Text_Key": "Tanca",
  "negative_feedback_Text_Key": "Lamentem que no hagis rebut la resposta que estaves buscant. Vols ajudar-nos a millorar?",
  "no_results_Text_Key": "No s´han trobat resultats per als termes de cerca que heu proporcionat: {{query}}",
  "product_error_Text_Key": "Ho sentim, no hem trobat resultats. Aquest cercador només admet una o més paraules claus. Si desitges informació sobre els nostres articles utilitza el cercador principal situat en la part superior de la web. Si desitges consultar l´estat de la teva comanda, si us plau, fes clic",
  "read_more_button_Text_Key": "Llegir més",
  "results_Text_Key": "Hem trobat {{number}} resultat(s) pera: {{query}}",
  "retrieving_Text_Key": "Recuperació",
  "searchbar_Text_Key": "Escriu una paraula o frase, per exemple, comanda o modificar.",
  "searching_Text_Key": "Cercant...",
  "showLess_Text_Key": "Mostra'n menys",
  "showMore_Text_Key": "Mostra'n més",
  "spellcheck_Text_Key": "Proveu de revisar l´ortografia o d´utilitzar paraules clau diferents",
  "submit_Text_Key": "Envia'l",
  "try_again_Text_Key": "Si us plau, intenta-ho més tard"
}