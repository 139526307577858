{
  "archived_Desc_Key": "Vous trouverez plus d'informations en cliquant ici.",
  "archived_Title_Key": "Ce document a été supprimé.",
  "carousel_Text_Key": "Cette information n'était pas utile? Essayez l'un des documents ci-dessous",
  "click_prompt_Text_Key": "cliquez ici",
  "close_Text_Key": "Fermer",
  "customerService_Text_Key": "Informations pratiques",
  "error_Text_Key": "Une erreur s'est produite.",
  "feedback_Option1_Text_Key": "Le texte est difficile à comprendre",
  "feedback_Option2_Text_Key": "Le texte n'est pas pertinent par rapport à ma question",
  "feedback_Option3_Text_Key": "Le texte est pertinent, mais il n'est pas utile",
  "feedback_Option4_Text_Key": "Le texte est trop long ou trop court ",
  "feedback_Question_1_Text_Key": "Quelles sont les parties de nos documents que nous pourrions améliorer ?",
  "feedback_Rec_Text_Key": "Merci pour votre retour",
  "feedback_Text_Key": "Avez-vous trouvé cette information utile?",
  "here_Text_Key": "ici.",
  "knowledge_Text_Key": "Base de connaissance",
  "link_prod_error_Text_Key": "https://www.ikea.com/be/fr/customer-service/track-manage-order/",
  "loading_Text_Key": "En cours de chargement",
  "market_Name_Key": "Belgique",
  "modal_button_Close_Text_Key": "Fermer",
  "negative_feedback_Text_Key": "Nous sommes désolés que vous n’ayez pas obtenu la réponse que vous cherchiez, souhaitez-vous nous aider à nous améliorer ?",
  "no_results_Text_Key": "Nous ne trouvons aucun résultat pour: {{query}}",
  "product_error_Text_Key": "Cette recherche ne concerne que la Foire aux questions. Pour les numéros d'articles, employez plutôt la barre de recherche située en haut des pages IKEA.be. Pour des informations sur votre commande, ",
  "read_more_button_Text_Key": "En savoir plus",
  "results_Text_Key": "Nous avons trouvé {{number}} résultats pour: {{query}}",
  "retrieving_Text_Key": "Récupération de données en cours",
  "searchbar_Text_Key": "Exemple: Livraison, retour d’article, click & collect",
  "searching_Text_Key": "En cours de recherche...",
  "showLess_Text_Key": "Afficher moins",
  "showMore_Text_Key": "Afficher plus",
  "spellcheck_Text_Key": "Essayez avec une autre orthographe ou d'autres mots-clés.",
  "submit_Text_Key": "Envoyer",
  "try_again_Text_Key": "Bitte versuche es später noch einmal."
}