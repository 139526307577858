module.exports = {
  colourTextAndIcon1: 'rgb(var(--colour-text-and-icon-1, 17, 17, 17))',
  colourTextAndIcon1Rgb: 'var(--colour-text-and-icon-1, 17, 17, 17)',
  colourTextAndIcon2: 'rgb(var(--colour-text-and-icon-2, 72, 72, 72))',
  colourTextAndIcon2Rgb: 'var(--colour-text-and-icon-2, 72, 72, 72)',
  colourTextAndIcon3: 'rgb(var(--colour-text-and-icon-3, 118, 118, 118))',
  colourTextAndIcon3Rgb: 'var(--colour-text-and-icon-3, 118, 118, 118)',
  colourTextAndIcon4: 'rgb(var(--colour-text-and-icon-4, 146, 146, 146))',
  colourTextAndIcon4Rgb: 'var(--colour-text-and-icon-4, 146, 146, 146)',
  colourTextAndIcon5: 'rgb(var(--colour-text-and-icon-5, 255, 255, 255))',
  colourTextAndIcon5Rgb: 'var(--colour-text-and-icon-5, 255, 255, 255)',
  colourNeutral1: 'rgb(var(--colour-neutral-1, 255, 255, 255))',
  colourNeutral1Rgb: 'var(--colour-neutral-1, 255, 255, 255)',
  colourNeutral2: 'rgb(var(--colour-neutral-2, 245, 245, 245))',
  colourNeutral2Rgb: 'var(--colour-neutral-2, 245, 245, 245)',
  colourNeutral3: 'rgb(var(--colour-neutral-3, 223, 223, 223))',
  colourNeutral3Rgb: 'var(--colour-neutral-3, 223, 223, 223)',
  colourNeutral4: 'rgb(var(--colour-neutral-4, 204, 204, 204))',
  colourNeutral4Rgb: 'var(--colour-neutral-4, 204, 204, 204)',
  colourNeutral5: 'rgb(var(--colour-neutral-5, 146, 146, 146))',
  colourNeutral5Rgb: 'var(--colour-neutral-5, 146, 146, 146)',
  colourNeutral6: 'rgb(var(--colour-neutral-6, 72, 72, 72))',
  colourNeutral6Rgb: 'var(--colour-neutral-6, 72, 72, 72)',
  colourNeutral7: 'rgb(var(--colour-neutral-7, 17, 17, 17))',
  colourNeutral7Rgb: 'var(--colour-neutral-7, 17, 17, 17)',
  colourSemanticInformative: 'rgb(var(--colour-semantic-informative, 0, 88, 163))',
  colourSemanticInformativeRgb: 'var(--colour-semantic-informative, 0, 88, 163)',
  colourSemanticPositive: 'rgb(var(--colour-semantic-positive, 10, 138, 0))',
  colourSemanticPositiveRgb: 'var(--colour-semantic-positive, 10, 138, 0)',
  colourSemanticCaution: 'rgb(var(--colour-semantic-caution, 242, 106, 47))',
  colourSemanticCautionRgb: 'var(--colour-semantic-caution, 242, 106, 47)',
  colourSemanticNegative: 'rgb(var(--colour-semantic-negative, 224, 7, 81))',
  colourSemanticNegativeRgb: 'var(--colour-semantic-negative, 224, 7, 81)',
  colourInteractivePrimaryBgDefault: 'rgb(var(--colour-interactive-primary-bg-default, 17, 17, 17))',
  colourInteractivePrimaryBgDefaultRgb: 'var(--colour-interactive-primary-bg-default, 17, 17, 17)',
  colourInteractivePrimaryBgHover: 'rgb(var(--colour-interactive-primary-bg-hover, 51, 51, 51))',
  colourInteractivePrimaryBgHoverRgb: 'var(--colour-interactive-primary-bg-hover, 51, 51, 51)',
  colourInteractivePrimaryBgPressed: 'rgb(var(--colour-interactive-primary-bg-pressed, 0, 0, 0))',
  colourInteractivePrimaryBgPressedRgb: 'var(--colour-interactive-primary-bg-pressed, 0, 0, 0)',
  colourInteractiveEmphasisedBgDefault: 'rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163))',
  colourInteractiveEmphasisedBgDefaultRgb: 'var(--colour-interactive-emphasised-bg-default, 0, 88, 163)',
  colourInteractiveEmphasisedBgHover: 'rgb(var(--colour-interactive-emphasised-bg-hover, 0, 79, 147))',
  colourInteractiveEmphasisedBgHoverRgb: 'var(--colour-interactive-emphasised-bg-hover, 0, 79, 147)',
  colourInteractiveEmphasisedBgPressed: 'rgb(var(--colour-interactive-emphasised-bg-pressed, 0, 62, 114))',
  colourInteractiveEmphasisedBgPressedRgb: 'var(--colour-interactive-emphasised-bg-pressed, 0, 62, 114)',
  colourInteractiveSecondaryBgDefault: 'rgb(var(--colour-interactive-secondary-bg-default, 245, 245, 245))',
  colourInteractiveSecondaryBgDefaultRgb: 'var(--colour-interactive-secondary-bg-default, 245, 245, 245)',
  colourInteractiveSecondaryBgHover: 'rgb(var(--colour-interactive-secondary-bg-hover, 223, 223, 223))',
  colourInteractiveSecondaryBgHoverRgb: 'var(--colour-interactive-secondary-bg-hover, 223, 223, 223)',
  colourInteractiveSecondaryBgPressed: 'rgb(var(--colour-interactive-secondary-bg-pressed, 204, 204, 204))',
  colourInteractiveSecondaryBgPressedRgb: 'var(--colour-interactive-secondary-bg-pressed, 204, 204, 204)',
  colourInteractiveSubtleBgDefault: 'rgb(var(--colour-interactive-subtle-bg-default, 255, 255, 255))',
  colourInteractiveSubtleBgDefaultRgb: 'var(--colour-interactive-subtle-bg-default, 255, 255, 255)',
  colourInteractiveSubtleBgHover: 'rgb(var(--colour-interactive-subtle-bg-hover, 245, 245, 245))',
  colourInteractiveSubtleBgHoverRgb: 'var(--colour-interactive-subtle-bg-hover, 245, 245, 245)',
  colourInteractiveSubtleBgPressed: 'rgb(var(--colour-interactive-subtle-bg-pressed, 223, 223, 223))',
  colourInteractiveSubtleBgPressedRgb: 'var(--colour-interactive-subtle-bg-pressed, 223, 223, 223)',
  colourInteractiveDestructiveBgDefault: 'rgb(var(--colour-interactive-destructive-bg-default, 224, 7, 81))',
  colourInteractiveDestructiveBgDefaultRgb: 'var(--colour-interactive-destructive-bg-default, 224, 7, 81)',
  colourInteractiveDestructiveBgHover: 'rgb(var(--colour-interactive-destructive-bg-hover, 204, 0, 61))',
  colourInteractiveDestructiveBgHoverRgb: 'var(--colour-interactive-destructive-bg-hover, 204, 0, 61)',
  colourInteractiveDestructiveBgPressed: 'rgb(var(--colour-interactive-destructive-bg-pressed, 184, 0, 41))',
  colourInteractiveDestructiveBgPressedRgb: 'var(--colour-interactive-destructive-bg-pressed, 184, 0, 41)',
  colourInteractiveDisabled1: 'rgb(var(--colour-interactive-disabled-1, 223, 223, 223))',
  colourInteractiveDisabled1Rgb: 'var(--colour-interactive-disabled-1, 223, 223, 223)',
  colourInteractiveDisabled2: 'rgb(var(--colour-interactive-disabled-2, 146, 146, 146))',
  colourInteractiveDisabled2Rgb: 'var(--colour-interactive-disabled-2, 146, 146, 146)',
  colourInteractiveDisabled3: 'rgb(var(--colour-interactive-disabled-3, 255, 255, 255))',
  colourInteractiveDisabled3Rgb: 'var(--colour-interactive-disabled-3, 255, 255, 255)',
  colourInteractiveEmphasisedBorderSelected: 'rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))',
  colourInteractiveEmphasisedBorderSelectedRgb: 'var(--colour-interactive-emphasised-border-selected, 0, 88, 163)',
  colourInteractiveSubtleBorderSelected: 'rgb(var(--colour-interactive-subtle-border-selected, 17, 17, 17))',
  colourInteractiveSubtleBorderSelectedRgb: 'var(--colour-interactive-subtle-border-selected, 17, 17, 17)',
  colourInteractiveSubtleBorderDefault: 'rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))',
  colourInteractiveSubtleBorderDefaultRgb: 'var(--colour-interactive-subtle-border-default, 146, 146, 146)',
  colourInteractiveSubtleBorderHover: 'rgb(var(--colour-interactive-subtle-border-hover, 17, 17, 17))',
  colourInteractiveSubtleBorderHoverRgb: 'var(--colour-interactive-subtle-border-hover, 17, 17, 17)',
  colourInteractiveSubtleBorderPressed: 'rgb(var(--colour-interactive-subtle-border-pressed, 17, 17, 17))',
  colourInteractiveSubtleBorderPressedRgb: 'var(--colour-interactive-subtle-border-pressed, 17, 17, 17)',
  colourStaticIkeaBrandBlue: 'rgb(var(--colour-static-ikea-brand-blue, 0, 88, 163))',
  colourStaticIkeaBrandBlueRgb: 'var(--colour-static-ikea-brand-blue, 0, 88, 163)',
  colourStaticIkeaBrandYellow: 'rgb(var(--colour-static-ikea-brand-yellow, 255, 219, 0))',
  colourStaticIkeaBrandYellowRgb: 'var(--colour-static-ikea-brand-yellow, 255, 219, 0)',
  colourStaticIkeaFamily: 'rgb(var(--colour-static-ikea-family, 0, 124, 193))',
  colourStaticIkeaFamilyRgb: 'var(--colour-static-ikea-family, 0, 124, 193)',
  colourStaticWhite: 'rgb(var(--colour-static-white, 255, 255, 255))',
  colourStaticWhiteRgb: 'var(--colour-static-white, 255, 255, 255)',
  colourStaticBlack: 'rgb(var(--colour-static-black, 17, 17, 17))',
  colourStaticBlackRgb: 'var(--colour-static-black, 17, 17, 17)',
  colourStaticDarkGrey: 'rgb(var(--colour-static-dark-grey, 118, 118, 118))',
  colourStaticDarkGreyRgb: 'var(--colour-static-dark-grey, 118, 118, 118)',
  colourStaticGrey: 'rgb(var(--colour-static-grey, 223, 223, 223))',
  colourStaticGreyRgb: 'var(--colour-static-grey, 223, 223, 223)',
  colourStaticLightGrey: 'rgb(var(--colour-static-light-grey, 245, 245, 245))',
  colourStaticLightGreyRgb: 'var(--colour-static-light-grey, 245, 245, 245)',
  colourElevation1: 'rgb(var(--colour-elevation-1, 255, 255, 255))',
  colourElevation1Rgb: 'var(--colour-elevation-1, 255, 255, 255)',
  colourElevation2: 'rgb(var(--colour-elevation-2, 255, 255, 255))',
  colourElevation2Rgb: 'var(--colour-elevation-2, 255, 255, 255)',
  colourElevation3: 'rgb(var(--colour-elevation-3, 17, 17, 17))',
  colourElevation3Rgb: 'var(--colour-elevation-3, 17, 17, 17)',
  colourElevation1Border: 'rgb(var(--colour-elevation-1-border, 223, 223, 223))',
  colourElevation1BorderRgb: 'var(--colour-elevation-1-border, 223, 223, 223)',
  colourElevation2Border: 'rgb(var(--colour-elevation-2-border, 72, 72, 72))',
  colourElevation2BorderRgb: 'var(--colour-elevation-2-border, 72, 72, 72)',
  colourCommercialMessageNew: 'rgb(var(--colour-commercial-message-new, 202, 80, 8))',
  colourCommercialMessageNewRgb: 'var(--colour-commercial-message-new, 202, 80, 8)',
  colourCommercialMessageNewLowerPrice: 'rgb(var(--colour-commercial-message-new-lower-price, 204, 0, 8))',
  colourCommercialMessageNewLowerPriceRgb: 'var(--colour-commercial-message-new-lower-price, 204, 0, 8)',
  colourCommercialMessageIkeaFamily: 'rgb(var(--colour-commercial-message-ikea-family, 0, 124, 193))',
  colourCommercialMessageIkeaFamilyRgb: 'var(--colour-commercial-message-ikea-family, 0, 124, 193)',
  colourCommercialMessageBtiYellow: 'rgb(var(--colour-commercial-message-bti-yellow, 255, 219, 0))',
  colourCommercialMessageBtiYellowRgb: 'var(--colour-commercial-message-bti-yellow, 255, 219, 0)',
  colourCommercialMessageBtiRed: 'rgb(var(--colour-commercial-message-bti-red, 204, 0, 8))',
  colourCommercialMessageBtiRedRgb: 'var(--colour-commercial-message-bti-red, 204, 0, 8)',
  colourCampaignSustainability: 'rgb(var(--colour-campaign-sustainability, 0, 133, 62))',
  colourCampaignSustainabilityRgb: 'var(--colour-campaign-sustainability, 0, 133, 62)'
};
