export const DomainName: string = "search"

export const langMapper = (lang: string) => {
    const langMap: { [key: string]: string } = {
        "nl-NL":"nl-NL",
        "nl-EN":"en-NL",
        "en-EN":"en-EN",
        "fr-FR":"fr-FR",
        "ca-FR":"fr-CA",
        "ca-EN":"en-CA",
        "se-SV":"sv-SE",
        "se-EN":"en-SE",
        "ch-DE":"de-CH",
        "ch-FR":"fr-CH",
        "ch-IT":"it-CH",
        "ch-EN":"en-CH",
        "pl-PL":"pl-PL",
        "ro-RO":"ro-RO",
        "rs-SR":"sr-RS",
        "sk-SK":"sk-SK",
        "cz-CS":"cs-CZ",
        "hr-HR":"hr-HR",
        "si-SL":"sl-SI",
        "de-DE":"de-DE",
        "de-EN":"en-DE",
        "dk-DA":"da-DK",
        "no-NO":"no-NO",
        "jp-JA":"ja-JP",
        "jp-EN":"en-JP",
        "fi-FI":"fi-FI",
        "fi-EN":"en-FI",
        "pt-PT":"pt-PT",
        "pt-EN":"en-PT",
        "at-DE":"de-AT",
        "at-EN":"en-AT",
        "it-IT":"it-IT",
        "kr-KO":"ko-KR",
        "kr-EN":"en-KR",
        "us-EN":"en-US",
        "us-ES":"es-US",
        "gb-EN":"en-GB",
        "au-EN":"en-AU",
        "in-EN":"en-IN",
        "ie-EN":"en-IE",
        "es-EN":"en-ES",
        "es-ES":"es-ES",
        "es-GL":"gl-ES",
        "es-EU":"eu-ES",
        "es-CA":"ca-ES",
        "be-FR":"fr-BE",
        "be-EN":"en-BE",
        "be-NL":"nl-BE",
        "ua-UK":"uk-UA",
        "hu-HU":"hu-HU",
        "ru-RU":"ru-RU",
        "r1-EN":"en-R1",
        "re-RV":"rv-RE"
    }
    if(lang in langMap){
        return langMap[lang]
      }
    return lang
  };