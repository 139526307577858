{
  "archived_Desc_Key": "고객지원 페이지에서 더 많은 정보를 확인해보세요.",
  "archived_Title_Key": "문제가 발생했습니다. 다른 정보를 확인해주세요.",
  "carousel_Text_Key": "원하는 정보를 찾지 못하셨다면 아래에 있는 연관 정보들을 확인해보세요.",
  "click_prompt_Text_Key": "주문 조회 및 관리",
  "close_Text_Key": "종료",
  "customerService_Text_Key": "고객지원",
  "error_Text_Key": "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요.",
  "feedback_Option1_Text_Key": "이 정보는 이해하기 어렵습니다",
  "feedback_Option2_Text_Key": "이 정보는 내 질문과 관련이 없습니다",
  "feedback_Option3_Text_Key": "이 정보는 관련이 있지만 도움은 되지 않습니다",
  "feedback_Option4_Text_Key": "이 정보는 너무 길거나 너무 짧습니다 ",
  "feedback_Question_1_Text_Key": "이 정보의 특정 부분이 만족스럽지 않거나 도움이 되지 않았나요?",
  "feedback_Rec_Text_Key": "소중한 피드백 감사드려요!",
  "feedback_Text_Key": "이 정보가 도움이 되었나요?",
  "here_Text_Key": "고객지원 페이지",
  "knowledge_Text_Key": "문의사항",
  "link_prod_error_Text_Key": "https://order.ikea.com/kr/ko/purchases/lookup/",
  "loading_Text_Key": "처리중",
  "market_Name_Key": "대한민국",
  "modal_button_Close_Text_Key": "종료",
  "negative_feedback_Text_Key": "원하는 답변을 드리지 못해 죄송합니다. 개선을 위해 도와주시겠어요?",
  "no_results_Text_Key": "검색 조건에 맞는 결과를 찾지 못했습니다.",
  "product_error_Text_Key": "제품 정보 검색을 원하신다면 IKEA 웹사이트 상단 검색 바를 이용해주세요.",
  "read_more_button_Text_Key": "더 보기",
  "results_Text_Key": "{{query}} 을(를) 통해 {{number}} 개의 결과를 찾았습니다.",
  "retrieving_Text_Key": "검색",
  "searchbar_Text_Key": "검색 예시 : 교환환불, 배송약관 등",
  "searching_Text_Key": "검색중",
  "showLess_Text_Key": "간략히",
  "showMore_Text_Key": "더 보기",
  "spellcheck_Text_Key": "명칭이 올바른지 한번 더 확인해주세요.",
  "submit_Text_Key": "제출",
  "try_again_Text_Key": "다시 시도해 주세요."
}