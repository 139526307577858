"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = require("prop-types");
var _classnames = _interopRequireDefault(require("classnames"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const LoadingLinear = _ref => {
  let {
    className,
    prefix,
    loadingValue,
    loadingMax,
    loadingLabel,
    ...otherProps
  } = _ref;
  const baseClass = `${prefix}loading__linear`;
  const linearWrapperClasses = (0, _classnames.default)(`${baseClass}-wrapper`, className);
  const currentValue = loadingValue > loadingMax ? loadingMax : loadingValue;
  return _react.default.createElement("span", _extends({}, otherProps, {
    className: linearWrapperClasses
  }), _react.default.createElement("label", {
    className: `${baseClass}-label`
  }, loadingLabel), _react.default.createElement("progress", {
    className: `${baseClass}-progress`,
    value: currentValue,
    max: loadingMax,
    "aria-valuemin": "0",
    "aria-valuenow": currentValue,
    "aria-valuemax": loadingMax
  }));
};
LoadingLinear.defaultProps = {
  prefix: '',
  loadingValue: 0,
  loadingMax: 100
};
var _default = LoadingLinear;
exports.default = _default;