{
  "archived_Desc_Key": "Aquí atopará máis axuda",
  "archived_Title_Key": "Este documento foi arquivado",
  "carousel_Text_Key": "Non lle resultou útil o artigo? Proba cun dos artigos relacionados a continuación",
  "click_prompt_Text_Key": "aquí",
  "close_Text_Key": "Pechar",
  "customerService_Text_Key": "Atención ao cliente",
  "error_Text_Key": "Algo foi mal",
  "feedback_Option1_Text_Key": "O texto non se entende ben",
  "feedback_Option2_Text_Key": "O texto non ten que ver coa miña pregunta",
  "feedback_Option3_Text_Key": "O texto ten que ver coa miña pregunta pero non é útil",
  "feedback_Option4_Text_Key": "O texto é demasiado longo ou demasiado curto ",
  "feedback_Question_1_Text_Key": "Exactamente, que partes dos nosos artigos consideras insatisfactorias ou pouco útiles?",
  "feedback_Rec_Text_Key": "Grazas pola túa opinión!",
  "feedback_Text_Key": "Cres que foi útil?",
  "here_Text_Key": "aquí",
  "knowledge_Text_Key": "Coñecemento",
  "link_prod_error_Text_Key": "https://www.ikea.com/es/gl/customer-service/track-manage-order/",
  "loading_Text_Key": "En proceso de descarga",
  "market_Name_Key": "España",
  "modal_button_Close_Text_Key": "Cerrar",
  "negative_feedback_Text_Key": "Lamentamos que non obtiveses a resposta que buscabas, queres axudarnos a mellorar?",
  "no_results_Text_Key": "Non atopamos ningunha coincidencia cos resultados da busca:  {{query}}",
  "product_error_Text_Key": "Sentímolo, non atopamos resultados. Este buscador só admite unha ou mais plalabras claves. Se desexas información sobre os nosos artigos utiliza o buscador principal ubicado na parte superior da web. Se desexas consultar o estado do teu pedido, por favor, fai click",
  "read_more_button_Text_Key": "Ler máis",
  "results_Text_Key": "Atopamos {{number}} resultado(s) para: {{query}}",
  "retrieving_Text_Key": "Estamos a recuperar a información",
  "searchbar_Text_Key": "Escribe unha plabra ou frase, por exemplo, pedido ou modificar.",
  "searching_Text_Key": "Buscando...",
  "showLess_Text_Key": "Mostrar menos",
  "showMore_Text_Key": "Mostrar máis",
  "spellcheck_Text_Key": "Comproba a ortografía ou emprega diferentes palabras clave",
  "submit_Text_Key": "Enviar",
  "try_again_Text_Key": "Si us plau, intenta-ho més tard"
}