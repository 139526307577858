{
  "archived_Desc_Key": "Plus d'informations disponibles ici",
  "archived_Title_Key": "Ce document a été archivé.",
  "carousel_Text_Key": "Ce document n'a pas été utile ? Tu peux essayer l'un des documents ci-dessous.",
  "click_prompt_Text_Key": "ici",
  "close_Text_Key": "Fermer",
  "customerService_Text_Key": "Service clientèle",
  "error_Text_Key": "Quelque chose n'a pas fonctionné",
  "feedback_Option1_Text_Key": "Le texte est difficile à comprendre",
  "feedback_Option2_Text_Key": "Le texte ne correspond pas à ma question",
  "feedback_Option3_Text_Key": "Le texte est pertinent mais inutile",
  "feedback_Option4_Text_Key": "Le texte est trop long ou trop court ",
  "feedback_Question_1_Text_Key": "Quelles parties de nos documents trouves-tu insatisfaisantes ou inutiles ?",
  "feedback_Rec_Text_Key": "Merci pour ton opinion !",
  "feedback_Text_Key": "Est-ce que cette information était utile ?",
  "here_Text_Key": "ici",
  "knowledge_Text_Key": "Encyclopédie",
  "link_prod_error_Text_Key": "https://www.ikea.com/ch/fr/customer-service/track-manage-order/",
  "loading_Text_Key": "Chargement en cours",
  "market_Name_Key": "Suisse",
  "modal_button_Close_Text_Key": "Fermer",
  "negative_feedback_Text_Key": "Nous sommes désolés que tu n’aies pas obtenu la réponse que tu cherchais. Veux-tu nous aider à nous améliorer?",
  "no_results_Text_Key": "Nous n’avons pas trouvé de résultat pour: {{query}}",
  "product_error_Text_Key": "Tu trouves ici seulement des informations venant de notre encyclopédie. Pour des informations sur un produit, inscris le numéro d'article dans la barre de recherche en-haut. Pour des informations sur ta commande, cherche-la dans",
  "read_more_button_Text_Key": "En savoir plus",
  "results_Text_Key": "Nous avons trouvé {{number}} résultat(s) pour: {{query}}",
  "retrieving_Text_Key": "Récupération en cours",
  "searchbar_Text_Key": "Ex.: livraison, retour (pas de numéro)",
  "searching_Text_Key": "Recherche en cours…",
  "showLess_Text_Key": "Montrer moins",
  "showMore_Text_Key": "Montrer plus",
  "spellcheck_Text_Key": "Vérifie l’orthographe ou utilise d’autres mots-clés",
  "submit_Text_Key": "Envoyer",
  "try_again_Text_Key": "Merci de réessayer ultérieurement"
}