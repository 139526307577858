"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _propTypes = require("prop-types");
var _variables = require("@ingka/variables");
var _expanderShared = require("@ingka/expander-shared");
var _classnames = _interopRequireDefault(require("classnames"));
var _button = _interopRequireDefault(require("@ingka/button"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Expander = _ref => {
  let {
    children,
    targetId,
    labelOpen,
    labelClosed,
    generic,
    open,
    prefix,
    duration,
    onToggle,
    ...other
  } = _ref;
  const contentWrapper = (0, _react.useRef)();
  const contentElement = (0, _react.useRef)();
  const animationFrameID = (0, _react.useRef)(null);
  const [expanded, setExpanded] = (0, _react.useState)(open);
  const isSSR = typeof window === 'undefined';
  const [animationState, setAnimationState] = (0, _react.useState)(_expanderShared.animationStates.EXPANDED);
  if (!isSSR) {
    (0, _react.useLayoutEffect)(() => {
      if (!expanded) {
        setAnimationState(_expanderShared.animationStates.COLLAPSED);
      }
    }, []);
  }
  (0, _react.useEffect)(() => {
    if (animationState === _expanderShared.animationStates.EXPANDED && expanded || animationState === _expanderShared.animationStates.COLLAPSED && !expanded) {
      return;
    }
    setAnimationState(expanded ? _expanderShared.animationStates.PREPARE_ANIMATE_FROM_COLLAPSED : _expanderShared.animationStates.PREPARE_ANIMATE_FROM_EXPANDED);
    return () => {
      cancelAnimationFrame(animationFrameID.current);
    };
  }, [expanded]);
  (0, _react.useEffect)(() => {
    if (animationState === _expanderShared.animationStates.PREPARE_ANIMATE_FROM_COLLAPSED) {
      setAnimationState(_expanderShared.animationStates.ANIMATING_TO_EXPANDED);
    } else if (animationState === _expanderShared.animationStates.PREPARE_ANIMATE_FROM_EXPANDED) {
      setAnimationState(_expanderShared.animationStates.ANIMATING_TO_COLLAPSED);
    }
  }, [animationState]);
  (0, _react.useEffect)(() => {
    setAnimationState(expanded ? _expanderShared.animationStates.EXPANDED : _expanderShared.animationStates.COLLAPSED);
    cancelAnimationFrame(animationFrameID.current);
  }, []);
  const toggleTarget = e => {
    e.preventDefault();
    const nextExpandedState = !expanded;
    if (nextExpandedState) {
      contentWrapper.current.focus();
    }
    setExpanded(nextExpandedState);
    if (onToggle) onToggle(e, nextExpandedState);
  };
  const ExpanderButton = () => {
    if (generic) {
      return _react.default.createElement(_button.default, _extends({
        className: btnClasses,
        "aria-expanded": expanded,
        "aria-controls": targetId,
        text: expanded ? labelOpen : labelClosed,
        prefix: prefix,
        type: "secondary",
        onClick: toggleTarget
      }, other));
    }
    return _react.default.createElement("button", _extends({
      className: btnClasses,
      "aria-expanded": expanded,
      "aria-controls": targetId,
      onClick: toggleTarget
    }, other), expanded ? labelOpen : labelClosed);
  };
  const baseClass = `${prefix}expander`;
  const rootClass = (0, _classnames.default)(baseClass);
  const btnClasses = (0, _classnames.default)(`${baseClass}__btn`, generic && `${baseClass}__btn--generic`);
  const classes = (0, _classnames.default)(`${baseClass}__content`);
  const marginBottom = generic ? '2rem' : '1.5rem';
  const isCollapsed = animationState === _expanderShared.animationStates.COLLAPSED;
  return _react.default.createElement("div", {
    className: rootClass
  }, _react.default.createElement("div", {
    className: classes,
    id: targetId,
    style: _expanderShared.getStyles[animationState](marginBottom, duration, contentElement.current ? contentElement.current.scrollHeight : null),
    onTransitionEnd: () => setAnimationState(expanded ? _expanderShared.animationStates.EXPANDED : _expanderShared.animationStates.COLLAPSED),
    "aria-hidden": isCollapsed,
    role: "region",
    tabIndex: expanded ? '0' : '-1',
    ref: contentWrapper
  }, _react.default.createElement("div", {
    style: {
      display: isCollapsed ? 'none' : null
    },
    ref: contentElement
  }, children)), _react.default.createElement(ExpanderButton, null));
};
Expander.defaultProps = {
  duration: parseInt(_variables.durationSlowWeb, 10),
  labelOpen: 'Read less',
  labelClosed: 'Read more',
  generic: false,
  open: false,
  prefix: ''
};
var _default = Expander;
exports.default = _default;