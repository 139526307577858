import React from 'react';
import styled from 'styled-components';
import Card from '@ingka/card';
import colourTokens from '@ingka/variables/colours-css';
import { ScreenReaderInfo } from '../StyledComponents';

const CardContainer = styled.div`
  margin: 8px 0 !important;
  span{
    span{ 
      display: none;
    }
  }
`;

const TextDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 220px;
  max-height: 220px;
`;
interface IArticleCardProps {
  id: string;
  title: string;
  category: string | undefined;
  onClick: () => void;
  onKeyPress: (e: React.KeyboardEvent) => void;
  description: string;
  variant:string;
}

class TitleComp extends React.Component<any, any> {
  render() {
    return <a>{this.props.title}</a>;
  }
}

class CatComp extends React.Component<any, any> {
  render() {
    return <a>{this.props.category}</a>;
  }
}

// The conditional check for variant is used for A/B-test. 
const ArticleCard: React.FC<IArticleCardProps> = ({
  id,
  title,
  category,
  variant,
  onClick,
  onKeyPress,
  description,
}) => {
  return  (
      <CardContainer>
      <Card
        label={category ? <CatComp category={category.substring(category.lastIndexOf("/") + 1)} /> as React.ReactElement: ''}
        body={description}
        id={id}
        title={(<TitleComp title={title} />) as React.ReactElement}
        tabIndex={0}
        backgroundColor={colourTokens.colourNeutral2}
        buttonVariant="icon-primary-inverse"
        emphasised
        onClick={onClick}
        onKeyPress={onKeyPress}
        ctaLabel="open article">
        {
          <>
            {/* <TextDescription>{description}</TextDescription> */}
            {/* {description} */}
            {/* add translation for this */}
            <ScreenReaderInfo>
              {'press enter to open full article'}
            </ScreenReaderInfo>
          </>
        }
      </Card>
    </CardContainer>
  )};

export default ArticleCard;
