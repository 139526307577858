import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ArticleCard from '../ArticleCard/';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from "@ingka/modal";
import Button from '@ingka/button'
import { sendGA} from '../../utils/Analytics';
import '../App.css'
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/expander/dist/style.css';
import '@ingka/aspect-ratio-box/dist/style.css';
import '@ingka/card/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/modal/dist/style.css';
import { GetDocumentResults } from '../../utils/HttpClient';

import {langStore} from '../../utils/translationObject'
interface ArticleListProps {
  data: any;
  lang: string;
  query: string;
  variant: 'standard'
  correlationId: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }

  span:hover {
    cursor: pointer;
  }

  h2:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const OpenInNewWindow = (lang: string) => {
  const articlePages = [
    'nl-NL',
    'en-NL',
    'fr-FR',
    'fr-CH',
    'it-CH',
    'de-CH',
    'en-CH',
    'fr-CA',
    'en-CA',
    'sv-SE',
    'en-SE',
    'pl-PL',
    'ro-RO',
    'sr-RS',
    'sk-SK',
    'cs-CZ',
    'hr-HR',
    'sl-SI',
    'de-DE',
    'da-DK',
    'no-NO',
    'ja-JP',
    'en-JP',
    'fi-FI',
    'en-FI',
    'pt-PT',
    'en-PT',
    'de-AT',
    'en-AT',
    'it-IT',
    "ko-KR",
    "en-KR",
    "en-GB",
    "en-AU",
    "en-US",
    "en-IN",
    "en-IE",
    "en-ES",
    "es-ES",
    "ca-ES",
    "eu-ES",
    "gl-ES",
    "hu-HU",
    "fr-BE",
    "nl-BE",
    "en-BE",
  ];
  if (articlePages.indexOf(lang) > -1) {
    return true;
  } else {
    return false;
  }
};

export interface IDocument {
  title: string, 
  description: string, 
  content: string 
}

const ArticleList: React.FC<ArticleListProps> = ({ data, lang, query, correlationId ,variant }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [docGuid, setDocGuid] = useState<string | null>(null);
  const [pos, setPos] = useState<null | undefined | number>(null);
  const [vetaDoc, setVetaDoc] = useState<IDocument>({title: "", description: "", content: ""});

  const { t } = useTranslation();

  const langKey: keyof typeof langStore = lang as any;
  const IsProduction = (): boolean => {
    const url = window.location.href;     
    return url.includes("www.ikea.com")
  } 


  useEffect(() => {
    async function retrieveDocs() {
      if (docGuid) {
       GetDocumentResults({
        locale: t('locale'),
        query,
        docGuid,
        correlationId,
        backend: 'lisa',
        isProd: IsProduction(),
        pos,
        setModalOpen,
        setVetaDoc
       })
      }
    }

    if (docGuid) {
      retrieveDocs();  
    }
  }, [data.content, data.length, docGuid, lang, pos, query, t]);

  const UrlExists = (url: string) => {
    if(window.location.hostname === "www.cte.ikeadt.com" || window.location.hostname === "localhost"){
       return true;
     }

    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status != 404)
      return true;
    else
      return false;
  }

  const closeFunc = () => {
    setModalOpen(false);
    setDocGuid("");
    setPos(null);
  }

  const renderItems = () => {
    const mapRows = data.map((item: any, index: number) => {
      return (
        <><ArticleCard
          id={item.metadata.docGUID}
          title={item.title}
          category={item?.metadata?.categoryPath}
          variant={variant}
          onClick={() => {
            setPos(index);
            const langCombo = lang.split('-');
            const market = langCombo[1];
            const mLang = langCombo[0];
            if (OpenInNewWindow(lang) && UrlExists(`https://www.ikea.com/${market.toLowerCase()}/${mLang.toLowerCase()}/customer-service/knowledge/articles/${item.metadata.docGUID}.html`)) {
              sendGA({
                guid: `${item.metadata.docGUID}`,
                action: 'articleOpen',
                query,
                variant,
                pos: index,
                correlationId: correlationId
              });             
              window.location.href=
                `https://www.ikea.com/${market.toLowerCase()}/${mLang.toLowerCase()}/customer-service/knowledge/articles/${item.metadata.docGUID}.html`
            } else {
              setDocGuid(item.metadata.docGUID);
            }
          } }
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              (document.activeElement as HTMLElement).blur();
              setPos(index);
              if (OpenInNewWindow(lang)) {
                sendGA({
                  guid: `${item.metadata.docGUID}`,
                  action: 'articleOpen',
                  query,
                  variant,
                  pos: index,
                  correlationId: correlationId
                });
                const langCombo = lang.split('-');
                const market = langCombo[1];
                const mLang = langCombo[0];
                window.location.href=
                  `https://www.ikea.com/${market.toLowerCase()}/${mLang.toLowerCase()}/customer-service/knowledge/articles/${item.metadata.docGUID}.html`;
              } else {
                setDocGuid(item.metadata.docGUID);
              }
            }
          } }
          description={item.description} /></>
        
      );
      
    });
    return mapRows;
  };
  return (
    <Container>
      <Modal
        visible={modalOpen} 
        handleCloseBtn={closeFunc}
      >
          <Sheets
            labelledById={vetaDoc.title}
            alignment={"right"} 
            preserveAlignment={false} 
            size={"medium"}  
            header={
              <ModalHeader 
                titleId={vetaDoc.title}
                title={vetaDoc.title}
                backBtnClick={closeFunc} 
                floating={false} 
              />
            }
            footer={
              <ModalFooter className='veta-btn-alignment'>
                  <Button text={langStore[langKey].close_Text_Key} type="primary" onClick={closeFunc}/>
              </ModalFooter>
            }
          >
            <ModalBody>
              <p>{vetaDoc.description}</p>
              <div dangerouslySetInnerHTML={{__html: vetaDoc.content}}></div>
            </ModalBody>
          </Sheets>
      </Modal>
      {renderItems()}
    </Container>
  );
};

export default ArticleList;
