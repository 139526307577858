{
  "archived_Desc_Key": "Lisäapua löytyy täältä",
  "archived_Title_Key": "Tämä asiakirja on arkistoitu.",
  "carousel_Text_Key": "Eikö artikkelista ollut apua? Kokeile alla näkyviä artikkeleita.",
  "click_prompt_Text_Key": "täältä",
  "close_Text_Key": "Sulje",
  "customerService_Text_Key": "Asiakaspalvelu",
  "error_Text_Key": "Jotakin meni pieleen",
  "feedback_Option1_Text_Key": "Tekstiä on vaikea ymmärtää",
  "feedback_Option2_Text_Key": "Sisältö ei liity kysymykseeni",
  "feedback_Option3_Text_Key": "Sisältö liittyy asiaan, muttei vastaa kysymykseeni",
  "feedback_Option4_Text_Key": "Sisältö on liian pitkä tai liian lyhyt ",
  "feedback_Question_1_Text_Key": "Mitkä osat sisällöistä eivät mielestäsi ole hyödyllisiä tai toimivia?",
  "feedback_Rec_Text_Key": "Kiitos palautteestasi!",
  "feedback_Text_Key": "Oliko tämä hyödyksesi?",
  "here_Text_Key": "tässä",
  "knowledge_Text_Key": "Tietoa",
  "link_prod_error_Text_Key": "https://www.ikea.com/fi/fi/customer-service/track-manage-order/",
  "loading_Text_Key": "Ladataan",
  "market_Name_Key": "Suomi",
  "modal_button_Close_Text_Key": "Sulje",
  "negative_feedback_Text_Key": "Olemme pahoillamme, ettet saanut etsimääsi vastausta, haluaisitko auttaa meitä parantamaan?",
  "no_results_Text_Key": "Hakutermille: {{query}} ei löytynyt yhtään tulosta",
  "product_error_Text_Key": "Tästä löydät vastauksia usein kysyttyihin kysymyksiin. Tuotenumerolla hakeminen onnistuu ylimmästä hakukentästä ja tilausnumerolla voit hakea.",
  "read_more_button_Text_Key": "Lue lisää",
  "results_Text_Key": "Löysimme {{number}} tulosta(s) haulle: {{query}}",
  "retrieving_Text_Key": "Haetaan",
  "searchbar_Text_Key": "Mistä kaipaat tietoa? Kirjoita esimerkiksi vaihtaminen, tilauksen seuranta.",
  "searching_Text_Key": "Etsitään...",
  "showLess_Text_Key": "Näytä vähemmän",
  "showMore_Text_Key": "Näytä enemmän",
  "spellcheck_Text_Key": "Ole hyvä ja tarkista oikeinkirjoitus tai kokeile toista hakusanaa. Tuotteen nimellä/artikkelinumerolla voit hakea tietoa ylemmästä Mitä etsit? hakupalkista.",
  "submit_Text_Key": "Lähetä",
  "try_again_Text_Key": "Ole hyvä ja yritä myöhemmin uudelleen"
}