{
  "archived_Desc_Key": "Du kan få mer hjelp her.",
  "archived_Title_Key": "Denne artikkelen er arkivert.",
  "carousel_Text_Key": "Hjalp ikke denne artikkelen deg? Prøv en av de lignende artiklene under.",
  "click_prompt_Text_Key": "Klikk her",
  "close_Text_Key": "Lukk",
  "customerService_Text_Key": "Kundeservice",
  "error_Text_Key": "Oops! Noe gikk galt.",
  "feedback_Option1_Text_Key": "Teksten er uklar og vanskelig å forstå",
  "feedback_Option2_Text_Key": "Teksten er ikke relevant for spørsmålet mitt",
  "feedback_Option3_Text_Key": "Teksten er relevant, men ikke nyttig",
  "feedback_Option4_Text_Key": "Teksten er for lang eller for kort ",
  "feedback_Question_1_Text_Key": "Hva gjorde at artikkelen ikke var nyttig for deg?",
  "feedback_Rec_Text_Key": "Takk for tilbakemeldingen!",
  "feedback_Text_Key": "Var dette nyttig?",
  "here_Text_Key": "her.",
  "knowledge_Text_Key": "Informasjon",
  "link_prod_error_Text_Key": "https://order.ikea.com/no/no/purchases/lookup/",
  "loading_Text_Key": "Laster...",
  "market_Name_Key": "Norge",
  "modal_button_Close_Text_Key": "Lukk",
  "negative_feedback_Text_Key": "Vi beklager at du ikke fikk svaret du lette etter. Vil du hjelpe oss med å forbedre oss?",
  "no_results_Text_Key": "Vi fant ingen treff når vi søkte på: {{query}}",
  "product_error_Text_Key": "Ingen treff på: {{query}} Her finner du informasjon og svar på spørsmål. Bruk søkefeltet øverst for å søke etter produkter. Ser du etter informasjon om ordren din,",
  "read_more_button_Text_Key": "Les mer",
  "results_Text_Key": "Vi fant {{number}} resultat(er) for: {{query}}",
  "retrieving_Text_Key": "Laster inn...",
  "searchbar_Text_Key": "Fortell oss hva du trenger hjelp med...",
  "searching_Text_Key": "Leter etter svar...",
  "showLess_Text_Key": "Vis mindre",
  "showMore_Text_Key": "Vis mer",
  "spellcheck_Text_Key": "Sjekk stavingen eller forsøk gjerne andre søkeord.",
  "submit_Text_Key": "Send inn",
  "try_again_Text_Key": "Vennligst prøv igjen senere."
}