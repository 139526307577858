{
  "archived_Desc_Key": "Dodatnu pomoć možeš pronaći ovde.",
  "archived_Title_Key": "Ovaj dokument je arhiviran.",
  "carousel_Text_Key": "Ovaj članak ti nije bio od pomoći? Pogledaj jedan od sličnih članaka u nastavku.",
  "click_prompt_Text_Key": "klikni ovde",
  "close_Text_Key": "Zatvori",
  "customerService_Text_Key": "Služba za kupce",
  "error_Text_Key": "Došlo je do greške",
  "feedback_Option1_Text_Key": "Tekst je zbunjujući i težak za razumevanje",
  "feedback_Option2_Text_Key": "Tekst nije relevantan za moje pitanje",
  "feedback_Option3_Text_Key": "Tekst je relevantan, ali nije od pomoći",
  "feedback_Option4_Text_Key": "Tekst je predugačak ili prekratak ",
  "feedback_Question_1_Text_Key": "Koji deo našeg članka smatraš nezadovoljavajućim ili nije koristan?",
  "feedback_Rec_Text_Key": "Hvala na povratnoj informaciji!",
  "feedback_Text_Key": "Da li ti je ovo bilo korisno?",
  "here_Text_Key": "ovde.",
  "knowledge_Text_Key": "Baza znanja",
  "link_prod_error_Text_Key": "https://www.ikea.com/rs/sr/customer-service/track-manage-order/",
  "loading_Text_Key": "Učitavanje",
  "market_Name_Key": "Srbija",
  "modal_button_Close_Text_Key": "Zatvori",
  "negative_feedback_Text_Key": "Žao nam je što nisi dobio/la traženi odgovor, da li bi želeo/la da nam pomogneš da se poboljšamo?",
  "no_results_Text_Key": "Nismo pronašli nijedan rezultat za tvoj unos: {{query}}",
  "product_error_Text_Key": "Ako tražiš neki proizvod, koristi polje za pretragu na vrhu stranice. Ukoliko ti je potrebna informacija u vezi s tvojom narudžbinom,",
  "read_more_button_Text_Key": "Pročitaj više",
  "results_Text_Key": "Pronašli smo {{number}} rezultat(a) za: {{query}}",
  "retrieving_Text_Key": "Prikupljanje",
  "searchbar_Text_Key": "Pokušaj, recimo: isporuka ili radno vreme",
  "searching_Text_Key": "Pretraga...",
  "showLess_Text_Key": "Prikaži manje",
  "showMore_Text_Key": "Prikaži više",
  "spellcheck_Text_Key": "Proveri da li je unos ispravan ili koristi druge ključne reči",
  "submit_Text_Key": "Pošalji",
  "try_again_Text_Key": "Pokušaj ponovo kasnije"
}